import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { useAuthContext } from '../../../../../context/context'
import { useSnackbar } from 'notistack'

const CommandTemplates: Command[] = [
  {
    name: 'uso',
    aliases: null,
    response:
      'Find out more about the USO and their mission here https://www.uso.org/',
    response_type: 'say',
    enabled: true,
    enabled_offline: true,
    enabled_online: true,
    global_cooldown: 5,
    user_cooldown: 15,
  },
  {
    name: 'wishbook',
    aliases: null,
    response:
      "Want to know how YOU'RE making an impact? Check it out here https://usowishbook.uso.org/",
    response_type: 'say',
    enabled: true,
    enabled_offline: true,
    enabled_online: true,
    global_cooldown: 5,
    user_cooldown: 15,
  },
  {
    name: 'join',
    aliases: null,
    response:
      'Want to join in and fundraise on your own? Find out how to here https://www.uso.org/gaming',
    response_type: 'say',
    enabled: true,
    enabled_offline: true,
    enabled_online: true,
    global_cooldown: 5,
    user_cooldown: 15,
  },
  {
    name: 'donate',
    aliases: null,
    response_type: 'say',
    response: 'Donate to the USO on behalf of the Hardcore community here: {{tiltify.url}}',
    enabled: true,
    enabled_offline: true,
    enabled_online: true,
    global_cooldown: 5,
    user_cooldown: 15,
  },
]

const FundraiserCommandTemplates: Command[] = [
  {
    name: 'fundraiser',
    aliases: null,
    response_type: 'say',
    response: 'Find out more about {{tiltify.name}} here: {{tiltify.url}}',
    enabled: true,
    enabled_offline: true,
    enabled_online: true,
    global_cooldown: 5,
    user_cooldown: 15,
  },
  {
    name: 'goal',
    aliases: null,
    // eslint-disable-next-line no-template-curly-in-string
    response_type: 'say',
    response: 'The fundraiser is at ${{tiltify.raised}}/${{tiltify.goal}}',
    enabled: true,
    enabled_offline: true,
    enabled_online: true,
    global_cooldown: 5,
    user_cooldown: 15,
  },
]

interface TemplatesProps {
  templatesOpen: boolean
  handleTemplatesClose: () => void
  snackbar: SnackbarProps
  setSnackbar: React.Dispatch<React.SetStateAction<SnackbarProps>>
  jwtToken: string
  rows: Command[]
  setRows: React.Dispatch<React.SetStateAction<Command[]>>
}

const Templates = ({
  templatesOpen,
  handleTemplatesClose,
  snackbar,
  setSnackbar,
  jwtToken,
  rows,
  setRows,
}: TemplatesProps) => {
  const { makeRequest, user, campaign } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()

  const createCommandFromTemplate = (cmd: Command) => {
    if (user !== null) {
      cmd.channel_id = user.account.channel_id
    }

    makeRequest('/modules/commands', 'POST', jwtToken, JSON.stringify(cmd))
      .then((data: any) => data)
      .then((json: any) => {
        const parsed = JSON.parse(json)
        if (parsed.data) {
          // Alert client it was successful
          enqueueSnackbar(
            `Successfully created the command "${cmd.name}" from templates.`,
            {
              variant: 'success',
            },
          )

          // Append the new command
          setRows([
            ...rows,
            {
              id: parsed.data.id,
              name: parsed.data.name,
              aliases: parsed.data.aliases,
              enabled: parsed.data.enabled,
              response: parsed.data.response,
              response_type: parsed.data.response_type,
              enabled_offline: parsed.data.enabled_offline,
              enabled_online: parsed.data.enabled_online,
              global_cooldown: parsed.data.global_cooldown,
              user_cooldown: parsed.data.user_cooldown,
              channel_id: parsed.data.channel_id,
              created_at: parsed.data.created_at,
              updated_at: parsed.data.updated_at,
              useage_count: parsed.data.useage_count,
            },
          ])
        }
        handleTemplatesClose()
      })
  }

  return (
    <Dialog open={templatesOpen} onClose={handleTemplatesClose}>
      <DialogTitle sx={{ paddingBottom: 0, margin: 0 }}>
        Command Templates
      </DialogTitle>
      <DialogContent>
        <Stack direction={'column'} spacing={2}>
          <DialogContentText id="alert-dialog-slide-description">
            Below are a list of commands that are provided when you created your
            account. If you deleted or edited them and want the old command
            back, click the "use" button.
          </DialogContentText>
          {CommandTemplates.map((item: Command, index: number) => {
            return (
              <>
                <Box>
                  <Stack
                    direction={'column'}
                    spacing={1}
                    key={'command-template-dialog-name-and-response'}
                  >
                    <Typography variant="h6">{item.name}</Typography>
                    <Typography>{item.response}</Typography>
                  </Stack>
                  <Button
                    startIcon={<FileCopyIcon />}
                    variant="contained"
                    sx={{ marginTop: 1 }}
                    onClick={() => {
                      createCommandFromTemplate(item)
                    }}
                  >
                    Use Template
                  </Button>
                </Box>
                {CommandTemplates.length === index + 1 ? <></> : <Divider />}
              </>
            )
          })}
          <Divider />
          {campaign !== null && campaign.name !== 'none' ? (
            FundraiserCommandTemplates.map((item: Command, index: number) => {
              return (
                <>
                  <Box>
                    <Stack
                      direction={'column'}
                      spacing={1}
                      key={'command-template-dialog-name-and-response'}
                    >
                      <Typography variant="h6">{item.name}</Typography>
                      <Typography>{item.response}</Typography>
                    </Stack>
                    <Button
                      startIcon={<FileCopyIcon />}
                      variant="contained"
                      sx={{ marginTop: 1 }}
                      onClick={() => {
                        createCommandFromTemplate(item)
                      }}
                    >
                      Use Template
                    </Button>
                  </Box>
                  {CommandTemplates.length === index + 1 ? <></> : <Divider />}
                </>
              )
            })
          ) : (
            <></>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleTemplatesClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Templates
