import { Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"

import DeleteIcon from "@mui/icons-material/Delete"
import { useAuthContext } from "../../../../../context/context"
import globalSettings from "../../../../../globalSettings"
import { useEffect, useState } from "react"

interface CommandEditDialogPropsFunc {
	setCommandEditDialog: React.Dispatch<React.SetStateAction<Command>>
	commandEditDialog: Command
	jwtToken: string
	rows: Command[]
	setRows: React.Dispatch<React.SetStateAction<Command[]>>
	user: OverallAccount | null
	cmdEditDialogOpen: boolean
	setCmdEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MAX_ALIASES = 5

const CommandEditDialog = ({ setCommandEditDialog, commandEditDialog, jwtToken, rows, setRows, user, cmdEditDialogOpen, setCmdEditDialogOpen }: CommandEditDialogPropsFunc) => {
	const { makeRequest } = useAuthContext()

	// Sets edit dialog back to the defaults and hides the dialog
	const defaults = () => {
		setCommandEditDialog({
			enabled: true,
			enabled_offline: true,
			enabled_online: true,
			global_cooldown: 5,
			user_cooldown: 10,
			channel_id: user?.account.channel_id,
			created_at: "",
			updated_at: "",
			useage_count: 0,
			aliases: [{ alias: "", command_id: 0, id: 0 }],
			id: 0,
			name: "",
			response: "",
			response_type: "say",
		})
		setCmdEditDialogOpen(false)
		setAliases(null)
	}

	const handleCommandDelete = (id: number | undefined, channel_id: number) => {
		const body = {
			id: id,
			channel_id: channel_id,
		}

		makeRequest("/modules/commands", "DELETE", jwtToken, JSON.stringify(body))
			.then((data: any) => data)
			.then((json: any) => {
				const parsed: APIRequest = JSON.parse(json)
				if (parsed.error) {
					console.log("Error deleting a command", parsed.error)
					return
				}

				if (parsed.data) {
					setRows(rows.filter((i: Command) => i.id !== id))
					defaults()
				}
			})
	}

	const handleSubmit = (event: any) => {
		event.preventDefault()

		let bodyAliases: CommandAlias[] | null = []
		if (aliases !== null) {
			aliases.forEach((aliasT) => {
				bodyAliases!.push({ alias: aliasT, command_id: commandEditDialog.id })
			})
		} else {
			bodyAliases = null
		}
		console.log("Command Edit Dialog:	", JSON.stringify(commandEditDialog, null, 2))
		const body = {
			channel_id: user?.account.channel_id,
			name: commandEditDialog.name,
			aliases: bodyAliases,
			response: commandEditDialog.response,
			response_type: commandEditDialog.response_type,
			enabled: commandEditDialog.enabled,
			enabled_offline: commandEditDialog.enabled_offline,
			enabled_online: commandEditDialog.enabled_online,
			global_cooldown: commandEditDialog.global_cooldown,
			user_cooldown: commandEditDialog.user_cooldown,
			created_at: commandEditDialog.created_at,
			updated_at: commandEditDialog.updated_at,
			id: commandEditDialog.id,
		}

		makeRequest("/modules/commands", "PUT", jwtToken, JSON.stringify(body))
			.then((data: any) => data)
			.then((json: any) => {
				const parsed: APIRequest = JSON.parse(json)

				if (parsed.error) {
					console.log("Error updating a command", parsed.error)
					return
				}

				if (parsed.data) {
					// Remove the old command from rows
					setRows(
						rows.map((item) =>
							item.id === parsed.data.id
								? {
										...item,
										channel_id: parsed.data.channel_id,
										name: parsed.data.name,
										aliases: parsed.data.aliases,
										response: parsed.data.response,
										enabled: parsed.data.enabled,
										enabled_offline: parsed.data.enabled_offline,
										enabled_online: parsed.data.enabled_online,
										global_cooldown: parsed.data.global_cooldown,
										user_cooldown: parsed.data.user_cooldown,
										created_at: parsed.data.created_at,
										updated_at: parsed.data.updated_at,
										id: parsed.data.id,
								  }
								: item
						)
					)
				}
			})

		// Close after submission
		setCmdEditDialogOpen(false)
		// Set the form values back to their defaults
		defaults()
	}

	const handleInputChange = (e: any) => {
		const { name, value } = e.target

		console.log("Change ", name, value)

		setCommandEditDialog({
			...commandEditDialog,
			[name]: value,
		})
	}

	const [aliases, setAliases] = useState<string[] | null>(null)

	useEffect(() => {
		const newArray: string[] = []
		if (commandEditDialog.aliases !== null) {
			commandEditDialog.aliases.forEach((alias) => {
				console.log(commandEditDialog.id, alias.command_id)
				newArray.push(alias.alias)
			})

			console.log("HERE!", newArray)

			setAliases(newArray)
		}
	}, [commandEditDialog.aliases, commandEditDialog.id])

	return (
		<Dialog fullWidth sx={{ width: "100%" }} open={cmdEditDialogOpen} onClose={defaults}>
			<form /* onSubmit={handleSubmit} */>
				<Grid container alignItems="center">
					<Grid>
						<DialogTitle>Editing command: {commandEditDialog.name}</DialogTitle>
					</Grid>
					<Grid sx={{ marginLeft: "auto" }}>
						<Box>
							<FormControlLabel
								value="end"
								control={<Checkbox checked={commandEditDialog.enabled} />}
								onChange={() => {
									setCommandEditDialog({
										...commandEditDialog,
										enabled: !commandEditDialog.enabled,
									})
								}}
								label="Enabled"
								labelPlacement="end"
							/>
						</Box>
					</Grid>
				</Grid>
				<DialogContent>
					<Stack direction="column" spacing={2} sx={{ width: "100%", height: "100%" }}>
						<Box>
							<Stack direction={"column"} spacing={2}>
								<TextField error={commandEditDialog.name.length > globalSettings.forms.COMMAND_NAME_MAX ? true : false} helperText={commandEditDialog.name.length > globalSettings.forms.COMMAND_NAME_MAX ? `The maximum amount of characters is ${globalSettings.forms.COMMAND_NAME_MAX} characters` : ""} id="name" name="name" label="Name" type="text" fullWidth variant="outlined" required={true} value={commandEditDialog.name} onChange={handleInputChange} />
								{aliases !== null ? (
									<Autocomplete
										multiple
										id="tags-filled"
										options={[]}
										title="Aliases"
										defaultValue={aliases !== null ? aliases : []}
										freeSolo
										onChange={(e, newValue) => {
											if (typeof newValue === "string") {
												setAliases([newValue])
											} else {
												setAliases(newValue as string[])
											}
										}}
										onKeyDown={(ev: any) => {
											if (ev.key === "Enter") {
												ev.preventDefault()
												// Initialize aliases if array is empty
												if (aliases == null) {
													setAliases([ev.target.value])
												} else {
													// Append the new alias to the list
													setAliases([...aliases!, ev.target.value])
												}
											}
										}}
										renderTags={(value: string[][], getTagProps) =>
											value.map((option: string[], index: number) => {
												return <Chip variant="filled" label={option} {...getTagProps({ index })} />
											})
										}
										renderInput={(params) => <TextField {...params} error={commandEditDialog.aliases !== null && commandEditDialog.aliases.length > MAX_ALIASES} variant="outlined" label="Aliases" placeholder="" helperText={"Type in an alias and press enter to submit."} />}
									/>
								) : (
									<Autocomplete multiple id="tags-filled" options={[]} title="Aliases" freeSolo renderInput={(params) => <TextField variant="outlined" label="Aliases" placeholder="" helperText={"Type in an alias and press enter to submit."} />} />
								)}

								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">Type of response</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={commandEditDialog.response_type}
										label="Type of response"
										onChange={(e) => {
											setCommandEditDialog({
												...commandEditDialog,
												response_type: e.target.value as "say" | "mention" | "reply",
											})
										}}
									>
										<MenuItem value={"say"}>
											<Stack>
												<Typography>Say</Typography>
												<Typography variant="caption">Simply says the message in chat without replying or mentioning anyone.</Typography>
											</Stack>
										</MenuItem>
										<MenuItem value={"mention"}>
											<Stack>
												<Typography>Mention</Typography>
												<Typography variant="caption">{"Will @ the user when ran."}</Typography>
											</Stack>
										</MenuItem>
										<MenuItem value={"reply"}>
											<Stack>
												<Typography>Reply</Typography>
												<Typography variant="caption">Uses Twitch's reply feature.</Typography>
											</Stack>
										</MenuItem>
									</Select>
								</FormControl>
								<TextField error={commandEditDialog.response.length > 500 ? true : false} helperText={commandEditDialog.response.length > 500 ? "The maximum amount of characters is 500" : ""} multiline rows={6} id="response" name="response" label={"Response - " + commandEditDialog.response.length + "/500 characters"} type="text" fullWidth variant="outlined" defaultValue={commandEditDialog.response} required={true} value={commandEditDialog.response} onChange={handleInputChange} />
							</Stack>

							<Stack direction={"column"} spacing={1}>
								<Box>
									<Typography variant="subtitle1" sx={{ fontWeight: "bold", marginTop: 2 }}>
										Conditions
									</Typography>
									<Typography variant="subtitle2">Allow this command to be run when your stream is:</Typography>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={commandEditDialog.enabled_online}
													onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
														setCommandEditDialog({
															...commandEditDialog,
															enabled_online: checked,
														})
													}}
												/>
											}
											label="Online"
											onChange={(event: any) => {
												console.log(event)
											}}
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={commandEditDialog.enabled_offline}
													onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
														setCommandEditDialog({
															...commandEditDialog,
															enabled_offline: checked,
														})
													}}
												/>
											}
											label="Offline"
										/>
									</FormGroup>
								</Box>

								<Stack spacing={1}>
									<Typography variant="subtitle1" sx={{ fontWeight: "bold", marginTop: 2 }}>
										Cooldowns
									</Typography>

									<Stack direction={"row"} spacing={2}>
										<TextField
											id="global_cooldown_field"
											label="Global cooldown"
											defaultValue={commandEditDialog.global_cooldown}
											type="number"
											value={commandEditDialog.global_cooldown}
											onChange={(e) => {
												setCommandEditDialog({
													...commandEditDialog,
													global_cooldown: parseInt(e.target.value),
												})
											}}
											fullWidth
										/>
										<TextField
											id="user_cooldown_field"
											label="User cooldown"
											defaultValue={commandEditDialog.global_cooldown}
											type="number"
											value={commandEditDialog.user_cooldown}
											onChange={(e) => {
												setCommandEditDialog({
													...commandEditDialog,
													user_cooldown: parseInt(e.target.value),
												})
											}}
											fullWidth
										/>
									</Stack>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Grid container alignItems="center">
						<Grid>
							<Button startIcon={<DeleteIcon />} onClick={() => handleCommandDelete(commandEditDialog.id, user?.account.channel_id as number)} variant="contained" color="secondary">
								Delete Command
							</Button>
						</Grid>
						<Grid sx={{ marginLeft: "auto" }}>
							<Stack direction="row" spacing={2}>
								<Button variant="contained" onClick={defaults}>
									Cancel
								</Button>
								<Button variant="contained" type="submit" onClick={handleSubmit}>
									Submit
								</Button>
							</Stack>
						</Grid>
					</Grid>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default CommandEditDialog
