import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import {
  Box,
  CssBaseline,
  Toolbar,
  Typography,
  Divider,
  List,
  Avatar,
  Stack,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  Drawer,
} from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import AppProvider, { useAuthContext } from '../context/context'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import Status from './Dashboard/Tabs/Status/WidgetBoard'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ListItemIcon from '@mui/material/ListItemIcon'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import getTheme from '../utils/getTheme'
import ArticleIcon from '@mui/icons-material/Article'
import { ColorModeContext } from './App'
import Logo from '../images/logo'
import AccountDialog from './AccountDialog'
import ThemeSwitch from './ThemeSwitch'
import { useCookies } from 'react-cookie'
// import TimerIcon from '@mui/icons-material/Timer'

const drawerWidth = 195

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

type DashboardProps = {
  tab: string
  handleTabChange: (tab: string) => void
  campaign: Campaign | null
}

const NewDashboard = ({ tab, handleTabChange, campaign }: DashboardProps) => {
  const theme = getTheme()
  const {
    user,
    token,
    makeRequest,
    setLoggedIn,
    setUser,
    setToken,
    setCampaign,
  } = useAuthContext()
  const [open, setOpen] = React.useState(false)
  const [, , removeCookie] = useCookies(['uso-token'])
  const navigate = useNavigate()
  // Account dialog open or not
  const [accountDialogOpen, setAccountDialogOpen] = useState<boolean>(false)
  const colorTheme = useContext(ColorModeContext)

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  )

  const getTabTitle = useCallback(() => {
    return tab[0].toUpperCase() + tab.substring(1)
  }, [tab])

  useEffect(() => {
    document.title = `USOBot | ${getTabTitle()}`

    if (token === '' || token === undefined) {
      navigate('/')
    }
  })

  const getBodyData = useCallback(() => {
    if (tab === 'dashboard') {
      return <Status user={user} open={open} />
    } else return <Outlet />
  }, [tab, user, open])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleLogout = () => {
    makeRequest('/logout', 'GET', token)
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          console.log(parsed.error)
          return
        }

        if (parsed.data) {
          return
        }
      })
  }

  const tabs = [
    {
      tab: 'dashboard',
      link: '/dashboard',
      name: 'Dashboard',
      icon: (
        <DashboardIcon
          sx={{
            color:
              tab === 'dashboard'
                ? '#fff'
                : theme === 'light'
                ? 'rgba(0, 0, 0, 0.26)'
                : 'rgba(255, 255, 255, 0.35)',
          }}
        />
      ),
    },
    {
      tab: 'commands',
      link: '/dashboard/commands',
      name: 'Commands',
      icon: (
        <PriorityHighIcon
          sx={{
            color:
              tab === 'commands'
                ? '#fff'
                : theme === 'light'
                ? 'rgba(0, 0, 0, 0.26)'
                : 'rgba(255, 255, 255, 0.35)',
          }}
        />
      ),
    },
    /* {
      tab: 'timers',
      link: '/dashboard/timers',
      name: 'Timers',
      icon: (
        <TimerIcon
          sx={{
            color:
              tab === 'timers'
                ? '#fff'
                : theme === 'light'
                ? 'rgba(0, 0, 0, 0.26)'
                : 'rgba(255, 255, 255, 0.35)',
          }}
        />
      ),
    }, */
  ]

  return (
    <AppProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AccountDialog
          accountDialogOpen={accountDialogOpen}
          setAccountDialogOpen={setAccountDialogOpen}
        />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                if (open) {
                  handleDrawerClose()
                } else {
                  handleDrawerOpen()
                }
              }}
              edge="start"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>

            <Grid container alignItems="center">
              <Grid>
                <Typography variant="h6" noWrap component="div">
                  {getTabTitle()}
                </Typography>
              </Grid>
              <Grid sx={{ marginLeft: 'auto' }}>
                <Box sx={{ flexGrow: 0 }}>
                  <Stack direction="row" spacing={1}>
                    <ThemeSwitch
                      onClick={colorTheme.toggleColorMode}
                      themeStr={theme}
                    />
                    <Typography sx={{ alignSelf: 'center' }}>
                      {user?.twitch.display_name}
                    </Typography>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                          alt={user?.twitch.display_name}
                          src={user?.twitch.pfp}
                          title={user?.twitch.display_name}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          open={open}
        >
          <DrawerHeader>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={0.5}
            >
              <Grid item>
                <Logo />
              </Grid>
              <Grid item>
                <Typography variant="h5">Bot</Typography>
              </Grid>
            </Grid>
          </DrawerHeader>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <MenuItem
                key="logout"
                onClick={() => {
                  handleCloseUserMenu()
                  handleLogout()
                  setLoggedIn(false)
                  setUser(null)
                  setCampaign(null)
                  setToken(undefined)
                  removeCookie('uso-token')
                }}
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Link>
          </Menu>

          <Divider />
          <Box sx={{ overflow: 'auto' }}>
            <List disablePadding>
              {tabs.map((Tab) => (
                <Link to={Tab.link} style={{ textDecoration: 'none' }}>
                  <ListItem
                    key={Tab.name}
                    disablePadding
                    disableGutters
                    sx={{
                      backgroundImage:
                        tab === Tab.tab
                          ? theme === 'light'
                            ? 'linear-gradient(to right,#8d0e2f,#bb133e)'
                            : 'linear-gradient(to right,#001730,#002147)'
                          : '#ffffff',
                      color:
                        tab === Tab.tab
                          ? '#ffffff'
                          : theme === 'light'
                          ? '#002147'
                          : '#ffffff',
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleTabChange(Tab.tab)
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '46px' }}>
                        {Tab.icon}
                      </ListItemIcon>
                      <ListItemText primary={Tab.name} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
              <Divider />

              <ListItem
                key="documentation"
                disablePadding
                disableGutters
                sx={{
                  color: theme === 'light' ? '#002147' : '#ffffff',
                }}
              >
                <ListItemButton target="_blank" href="https://docs.usobot.org">
                  <ListItemIcon
                    sx={{
                      minWidth: '46px',
                      color:
                        theme === 'light'
                          ? 'rgba(0, 0, 0, 0.26)'
                          : 'rgba(255, 255, 255, 0.35)',
                    }}
                  >
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Documentation'} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar />
          <Main sx={{ padding: 1.5 }} open={open}>
            {getBodyData()}
          </Main>
        </Box>
      </Box>
    </AppProvider>
  )
}

export default NewDashboard
