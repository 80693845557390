import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SettingsIcon from '@mui/icons-material/Settings'
import PauseIcon from '@mui/icons-material/Pause'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../../../../context/context'
import { useSnackbar } from 'notistack'

const BoxBot = () => {
  const { makeRequest, user, token, setUser } = useAuthContext()
  const [open, setOpen] = useState<boolean>(false)
  const [isConnected, setIsConnected] = useState<boolean>(user?.bot.connected)
  const [newPrefix, setNewPrefix] = useState<string>(user?.bot.prefix)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setIsConnected(user?.bot.connected)
  }, [user?.bot.connected])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleBotToggle = (value: boolean) => {
    const body = {
      id: user?.bot.id,
      login: user?.twitch.login,
      channel_id: user?.twitch.id,
      enabled: value,
    }

    makeRequest('/bots', 'PUT', token, JSON.stringify(body))
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          console.log('ERROR', parsed.error)
          enqueueSnackbar(
            `Error ${value === true ? 'joining' : 'leaving'} your channel. ${
              parsed.error
            }`,
            {
              variant: 'error',
            },
          )
        }

        if (parsed.data === null) {
          setUser({
            ...user,
            bot: {
              id: user?.bot.id,
              login: user?.twitch.login,
              channel_id: user?.twitch.id,
              enabled: value,
              prefix: user?.bot.prefix,
            },
          })
          setIsConnected(value)
          enqueueSnackbar(
            `Successfully ${value === true ? 'joined' : 'left'} your channel.`,
            {
              variant: 'success',
            },
          )
        }
      })
  }

  const handlePrefixChange = () => {
    const body = {
      id: user?.bot.id,
      login: user?.twitch.login,
      channel_id: user?.twitch.id,
      prefix: newPrefix,
    }

    makeRequest('/bots', 'PUT', token, JSON.stringify(body))
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          console.log('ERROR', parsed.error)
          enqueueSnackbar(`Error changing you prefix: ${parsed.error}`, {
            variant: 'error',
          })
        }

        if (parsed.data === null) {
          setUser({
            ...user,
            bot: {
              id: user?.bot.id,
              login: user?.twitch.login,
              channel_id: user?.twitch.id,
              enabled: user?.bot.enabled,
              prefix: newPrefix,
            },
          })

          setOpen(false)
          enqueueSnackbar(`Successfully updated prefix.`, {
            variant: 'success',
          })
        }
      })
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={8}
        paddingTop={5}
      >
        <Stack direction="column" sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">Status</Typography>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            {isConnected ? 'Connected' : 'Disconnected'}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3}>
          {isConnected ? (
            <Button
              variant="contained"
              startIcon={<PauseIcon />}
              onClick={() => {
                handleBotToggle(false)
              }}
            >
              Disconnect
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<PlayArrowIcon />}
              onClick={() => {
                handleBotToggle(true)
              }}
              disabled={user === null}
            >
              Connect
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<SettingsIcon />}
            onClick={handleClickOpen}
            disabled={user === null}
          >
            Settings
          </Button>
        </Stack>
      </Stack>

      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Bot Settings</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Prefix"
              type="text"
              fullWidth
              variant="filled"
              defaultValue={user?.bot.prefix}
              onChange={(e) => {
                setNewPrefix(e.target.value)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              disabled={newPrefix === user?.bot.prefix}
              onClick={handlePrefixChange}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

export default BoxBot
