import { Fade, Slide, SlideProps, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { createContext, useEffect, useMemo, useState } from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AppProvider from '../context/context'
import '../index.css'
import getTheme from '../utils/getTheme'
import ChannelCommands from './Dashboard/Tabs/Commands/ChannelCommands'
import Commands from './Dashboard/Tabs/Commands/Commands'
import Timers from './Dashboard/Tabs/Timers/Timers'
import GoHome from './Home/GoHome'
import Home from './Home/Home'
import NewDashboard from './NewDashboard'

export function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />
}

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
})

const App = () => {
  useEffect(() => {
    if (localStorage.getItem('theme') === null) {
      localStorage.setItem('theme', 'light')
    }
  }, [])

  const [theme, setTheme] = useState<'light' | 'dark'>(getTheme())
  const colorTheme = useMemo(
    () => ({
      toggleColorMode: () => {
        const prev = getTheme()
        localStorage.setItem('theme', prev === 'light' ? 'dark' : 'light')
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
      },
    }),
    [],
  )

  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    shown: false,
    error: false,
    message: '',
    transition: Fade,
  })
  const [campaign] = useState<Campaign | null>(null)
  const [tab, setTab] = useState<string>(
    localStorage.getItem('tab') || 'dashboard',
  )
  const [cookies] = useCookies()

  const handleTabChange = (tab: string) => {
    localStorage.setItem('tab', tab)
    setTab(tab)
  }

  useEffect(() => {
    if (localStorage.getItem('tab') === null) {
      localStorage.setItem('tab', 'dashboard')
    }
  }, [cookies])

  const themeT = useMemo(
    () =>
      createTheme({
        components: {
          MuiDialogContent: {
            styleOverrides: {
              root: { paddingTop: `5px !important` },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                backgroundImage:
                  theme === 'light'
                    ? 'linear-gradient(to right,#8d0e2f,#bb133e)'
                    : 'linear-gradient(to right,#001730,#002147)',
                color: 'white',
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: theme === 'light' ? '#002147' : '#760c27',
              },
              track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: theme === 'light' ? '#760c27' : '#002147',
                '.Mui-checked.Mui-checked + &': {
                  // Controls checked color for the track
                  opacity: 0.9,
                  backgroundColor: theme === 'light' ? '#760c27' : '#002147',
                },
              },
            },
          },
          MuiLinearProgress: {
            styleOverrides: {
              barColorPrimary: {
                backgroundColor: theme === 'light' ? '#760c27' : '#002147',
              },
              colorPrimary: theme === 'light' ? '#760c27' : '#002147',
              root: {
                backgroundColor: 'gray',
                height: '10px',
                borderRadius: 5,
              },
            },
          },
        },
        palette: {
          mode: theme,
          primary: {
            main: theme === 'light' ? '#002147' : '#ffffff',
          },
          secondary: {
            main: '#760c27',
          },
          text: {
            primary: theme === 'light' ? '#002147' : '#ffffff',
            secondary: theme === 'light' ? 'rgba(0,33,71,0.54)' : 'white',
            disabled: 'rgba(0,33,71,0.38)',
          },
        },
      }),
    [theme],
  )

  return (
    <ColorModeContext.Provider value={colorTheme}>
      <ThemeProvider theme={themeT}>
        <SnackbarProvider dense>
          <AppProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/dashboard"
                  element={
                    <NewDashboard
                      tab={tab}
                      handleTabChange={handleTabChange}
                      campaign={campaign}
                    />
                  }
                >
                  <Route
                    path="commands"
                    element={
                      <Commands snackbar={snackbar} setSnackbar={setSnackbar} />
                    }
                  />
                  <Route path="timers" element={<Timers />} />
                </Route>
                <Route
                  path="/commands/:channel"
                  element={<ChannelCommands />}
                />
                <Route
                  path="*"
                  element={<GoHome text="Nothing to see here." />}
                />
              </Routes>
            </BrowserRouter>
          </AppProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root'),
)
