import {
  Popover,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Fab,
  Box,
} from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import WidgetsIcon from '@mui/icons-material/Widgets'
import { useAuthContext } from '../../../../context/context'
import { widgetNames } from './WidgetBoard'
const useStyles = makeStyles((theme: any) => ({
  popup: {
    padding: theme.spacing(2),
  },
}))

interface WidgetsMenuProps {
  items: any
  onRemoveItem: (itemId: any) => void
  onAddItem: (itemId: string) => void
  originalItems: string[]
}

export default function WidgetsMenu({
  items,
  onRemoveItem,
  onAddItem,
  originalItems,
}: WidgetsMenuProps) {
  const { user } = useAuthContext()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChange = (e: any) => {
    if (e.target.checked) {
      onAddItem(e.target.name)
    } else {
      onRemoveItem(e.target.name)
    }
  }

  return (
    <Box>
      <Box>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          onClick={handleClick}
        >
          <WidgetsIcon sx={{ mr: 1 }} />
          Widgets
        </Fab>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popup}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Widgets</FormLabel>
            <FormGroup>
              {originalItems
                .map((i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={items.includes(i)}
                        onChange={handleChange}
                        name={i}
                      />
                    }
                    label={widgetNames[i]}
                    key={i}
                  />
                ))
                .sort()}
            </FormGroup>
          </FormControl>
        </div>
      </Popover>
    </Box>
  )
}
