import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

type EditTimerDialogProps = {
  editDialogOpen: boolean
  setEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  editDialog: Timer | null
  setEditDialog: React.Dispatch<React.SetStateAction<Timer | null>>
  user: OverallAccount | null
  setRows: React.Dispatch<React.SetStateAction<Timer[] | null>>
  rows: Timer[] | null
  jwtToken: string
  makeRequest: any
}

const EditTimerDialog = ({
  editDialogOpen,
  setEditDialogOpen,
  editDialog,
  setEditDialog,
  user,
  setRows,
  rows,
  jwtToken,
  makeRequest,
}: EditTimerDialogProps) => {
  const MAX_MESSAGES = 3
  const MAX_COMMANDS = 3
  const MAX_CHAT_LINES = 5000
  const [commands, setCommands] = useState<string[]>([])
  const [messages, setMessages] = useState<string[]>([])

  useEffect(() => {
    if (editDialog !== null) {
      if (editDialog.messages !== null) {
        const msgs: string[] = []
        editDialog.messages.forEach((msg) => {
          msgs.push(msg.text)
        })
        setMessages(msgs)
      }

      if (editDialog.commands !== null) {
        editDialog.commands.forEach((cmd) => {
          setCommands([...commands, cmd.command!.name])
        })
      }
    }
  }, [editDialog, commands, messages])

  const handleEditDialogChange = (prop: keyof Timer) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (prop === 'online_interval' || prop === 'offline_interval') {
      setEditDialog({ ...editDialog!, [prop]: parseInt(event.target.value) })
      return
    }
    setEditDialog({ ...editDialog!, [prop]: event.target.value })
  }

  const handleClose = () => {
    setEditDialogOpen(false)
  }

  // Handles array of messages user wants to add to timer
  const addMessage = () => {
    if (editDialog !== null) {
      setEditDialog({
        ...editDialog,
        messages: [
          ...editDialog?.messages,
          { text: `Message #${editDialog.messages.length + 1}` },
        ],
      })
    }
  }

  const addCommand = () => {
    // if (messages.length > MAX_COMMANDS) return
    // setCommands([...commands, `NewCommand`])
  }

  const handleTimerDelete = (id: number, channel_id: number) => {
    const body = {
      id: id,
      channel_id: channel_id,
    }

    makeRequest('/modules/timers', 'DELETE', jwtToken, JSON.stringify(body))
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          console.log('Error deleting a timer', parsed.error)
          return
        }

        if (parsed.data) {
          if (rows?.length !== 0) {
            setRows(rows!.filter((i: Timer) => i.id !== id))
          }
          // defaults()
          setEditDialogOpen(false)
        }
      })
  }

  return editDialog !== null ? (
    <div>
      <Dialog open={editDialogOpen} onClose={handleClose}>
        <Grid container alignItems="center">
          <Grid>
            <DialogTitle>Editing timer: {editDialog.name}</DialogTitle>
          </Grid>
          <Grid sx={{ marginLeft: 'auto' }}>
            <Box>
              <FormControlLabel
                value="end"
                control={<Checkbox checked={editDialog.enabled} />}
                onChange={() => {
                  setEditDialog({
                    ...editDialog,
                    enabled: !editDialog.enabled,
                  })
                }}
                label="Enabled"
                labelPlacement="end"
              />
            </Box>
          </Grid>
        </Grid>

        <DialogContent>
          <Stack spacing={1.5}>
            <TextField
              error={editDialog.name.length > 255 ? true : false}
              helperText={
                editDialog.name.length > 255
                  ? 'Name is over 255 characters'
                  : ''
              }
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              defaultValue={editDialog?.name}
              // onInput={handleDialogChange('name')}
              type="text"
              fullWidth
              variant="outlined"
            />

            <Stack direction="row" spacing={16}>
              <Box>
                <FormControlLabel
                  value="end"
                  control={<Checkbox checked={editDialog.enabled_online} />}
                  onChange={() => {
                    setEditDialog({
                      ...editDialog,
                      enabled_online: !editDialog.enabled_online,
                    })
                  }}
                  label="Enabled online"
                  labelPlacement="end"
                />
              </Box>
              <Box>
                <FormControlLabel
                  value="end"
                  control={<Checkbox checked={editDialog.enabled_offline} />}
                  onChange={() => {
                    setEditDialog({
                      ...editDialog,
                      enabled_offline: !editDialog.enabled_offline,
                    })
                  }}
                  label="Enabled offline"
                  labelPlacement="end"
                />
              </Box>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Box>
                <OutlinedInput
                  id="timer-online-interval"
                  defaultValue={editDialog.online_interval}
                  value={editDialog.online_interval}
                  onChange={handleEditDialogChange('online_interval')}
                  endAdornment={
                    <InputAdornment position="end">minutes</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
                <FormHelperText id="outlined-weight-helper-text">
                  Execution frequency when stream is online
                </FormHelperText>
              </Box>

              <Box>
                <OutlinedInput
                  id="timer-offline-interval"
                  defaultValue={editDialog.offline_interval}
                  value={editDialog.offline_interval}
                  onChange={handleEditDialogChange('offline_interval')}
                  endAdornment={
                    <InputAdornment position="end">minutes</InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
                <FormHelperText id="outlined-weight-helper-text">
                  Execution frequency when stream is offline
                </FormHelperText>
              </Box>
            </Stack>
          </Stack>

          <Divider sx={{ mt: 1, mb: 1 }} />

          {/* Message responses */}
          <Stack spacing={4}>
            <Box>
              <Grid container alignItems="center">
                <Grid>
                  <Stack
                    direction={'row'}
                    sx={{ verticalAlign: 'center' }}
                    spacing={1}
                  >
                    <Typography variant="h6">Messages</Typography>
                    <Typography
                      sx={{
                        lineHeight: '35px',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                      variant="subtitle2"
                    >
                      Up to 3 messages
                    </Typography>
                  </Stack>
                </Grid>
                <Grid sx={{ marginLeft: 'auto' }}>
                  <Button
                    variant="contained"
                    disabled={
                      editDialog.messages.length > MAX_MESSAGES ? true : false
                    }
                    onClick={addMessage}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Stack direction="column" spacing={1}>
                {/* Message box with delete button */}
                {messages.map((msg, ind) => (
                  <Stack direction="row" spacing={2}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="message"
                      label={'Message #' + (ind + 1)}
                      type="text"
                      fullWidth
                      defaultValue={msg}
                      variant="outlined"
                      onChange={(e) => {
                        console.log(e.target.value)
                        editDialog.messages[ind] = { text: e.target.value }
                        setEditDialog({
                          ...editDialog,
                          messages: editDialog.messages,
                        })
                      }}
                    />
                    <Box>
                      <IconButton
                        sx={{
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                        aria-label="delete"
                        onClick={() => {
                          // Remove message from array by index
                          setEditDialog({
                            ...editDialog,
                            messages: editDialog.messages.filter(
                              (_, index) => index !== ind,
                            ),
                          })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Stack>

          <Divider sx={{ mt: 1, mb: 1 }} />

          {/* Command responses */}
          <Stack spacing={2}>
            <Box>
              <Grid container alignItems="center">
                <Grid>
                  <Stack
                    direction={'row'}
                    sx={{ verticalAlign: 'center' }}
                    spacing={1}
                  >
                    <Typography variant="h6">Commands</Typography>
                    <Typography
                      sx={{
                        lineHeight: '35px',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                      variant="subtitle2"
                    >
                      Up to 3 commands
                    </Typography>
                  </Stack>
                </Grid>
                <Grid sx={{ marginLeft: 'auto' }}>
                  <Button
                    variant="contained"
                    disabled={
                      editDialog.messages.length > MAX_MESSAGES ? true : false
                    }
                    onClick={addCommand}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>

              <Stack direction="column">
                {/* Message box with delete button */}
                {commands.map((msg, ind) => (
                  <Stack direction="row" spacing={2}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="message"
                      label={'Command #' + (ind + 1)}
                      defaultValue={msg}
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        // Remove message from array by index
                        setCommands((cmds) =>
                          cmds.filter((_, index) => index !== ind),
                        )
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            </Box>

            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box>
              <Alert severity="warning">
                <AlertTitle>This action cannot be undone!</AlertTitle>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() =>
                    handleTimerDelete(
                      editDialog!.id,
                      user?.account.channel_id as number,
                    )
                  }
                  variant="contained"
                >
                  Delete Timer
                </Button>
              </Alert>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <></>
  )
}

export default EditTimerDialog
