import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CreateCommandDialog from './Dialogue/Create'
import { Stack } from '@mui/system'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import Templates from './Dialogue/Templates'
import { Button } from '@mui/material'
import { useAuthContext } from '../../../../context/context'
import DataObjectIcon from '@mui/icons-material/DataObject'

type CreateCommandProps = {
  snackbar: SnackbarProps
  setSnackbar: React.Dispatch<React.SetStateAction<SnackbarProps>>
  jwtToken: string
  rows: Command[]
  setRows: React.Dispatch<React.SetStateAction<Command[]>>
}

const defaultValues: Command = {
  name: '',
  aliases: null,
  response: '',
  response_type: 'say',
  enabled: true,
  enabled_online: true,
  enabled_offline: true,
  global_cooldown: 5,
  user_cooldown: 15,
}

const CreateCommand = ({
  snackbar,
  setSnackbar,
  jwtToken,
  rows,
  setRows,
}: CreateCommandProps) => {
  const { makeRequest, user } = useAuthContext()
  const [open, setOpen] = useState<boolean>(false)
  const [templatesOpen, setTemplatesOpen] = useState<boolean>(false)
  const [formValues, setFormValues] = useState(defaultValues)
  const [view, setView] = React.useState('list')

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string,
  ) => {
    setView(nextView)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleTemplatesClickOpen = () => {
    setTemplatesOpen(true)
  }

  const handleClose = () => {
    setOpen(false)

    // Set the form values back to their defaults
    setFormValues(defaultValues)
  }

  const handleTemplatesClose = () => {
    setTemplatesOpen(false)
  }

  const handleDeleteAlias = (alias: string) => {
    if (formValues.aliases == null) return

    const newArr = formValues.aliases.filter((item: CommandAlias) => {
      return item.alias !== alias
    })

    setFormValues({
      ...formValues,
      aliases: newArr,
    })
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const [createCommandAliases, setCreateCommandAliases] = useState<string[]>([])

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const body = {
      channel_id: user?.account.channel_id, // TODO: find a way to get this from token/local storage
      id: formValues.id,
      name: formValues.name,
      aliases: createCommandAliases.length > 0 ? createCommandAliases : null,
      response: formValues.response,
      response_type: formValues.response_type,
      enabled: formValues.enabled,
      enabled_offline: formValues.enabled_offline,
      enabled_online: formValues.enabled_online,
      global_cooldown: formValues.global_cooldown,
      user_cooldown: formValues.user_cooldown,
      created_at: formValues.created_at,
      updated_at: formValues.updated_at,
      useage_count: formValues.useage_count,
    }

    makeRequest('/modules/commands', 'POST', jwtToken, JSON.stringify(body))
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.data) {
          const newCmd: any = {
            id: parsed.data.id,
            name: parsed.data.name,
            aliases: parsed.data.aliases,
            enabled: Boolean(parsed.data.enabled),
            response: parsed.data.response,
            response_type: parsed.data.response_type,
            enabled_offline: parsed.data.enabled_offline,
            enabled_online: parsed.data.enabled_online,
            global_cooldown: parsed.data.global_cooldown,
            user_cooldown: parsed.data.user_cooldown,
            created_at: parsed.data.created_at,
            updated_at: parsed.data.updated_at,
            useage_count: parsed.data.useage_count,
          }

          setRows([...rows, newCmd])
        }
      })

    // Close after submission
    setOpen(false)
    // Set the form values back to their defaults
    setFormValues(defaultValues)
  }

  // TODO: Make it so you can click a button to close a snackbar
  return (
    <div>
      <div>
        <Stack direction={'row'} spacing={2} sx={{ pb: 3 }}>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            startIcon={<AddIcon />}
          >
            Create
          </Button>

          <Button
            variant="contained"
            onClick={handleTemplatesClickOpen}
            startIcon={<FileCopyIcon />}
            fullWidth={false}
          >
            Templates
          </Button>

          <Button
            variant="contained"
            startIcon={<DataObjectIcon />}
            fullWidth={false}
            target="_blank"
            href="https://docs.usobot.org/category/variables"
          >
            Variable Documentation
          </Button>
        </Stack>

        <Templates
          setSnackbar={setSnackbar}
          snackbar={snackbar}
          handleTemplatesClose={handleTemplatesClose}
          templatesOpen={templatesOpen}
          jwtToken={jwtToken}
          rows={rows}
          setRows={setRows}
        />

        <CreateCommandDialog
          formValues={formValues}
          handleClose={handleClose}
          open={open}
          handleInputChange={handleInputChange}
          setFormValues={setFormValues}
          handleChange={handleChange}
          handleDeleteAlias={handleDeleteAlias}
          view={view}
          handleSubmit={handleSubmit}
          setCreateCommandAliases={setCreateCommandAliases}
          createCommandAliases={createCommandAliases}
        />
      </div>
    </div>
  )
}

export default CreateCommand
