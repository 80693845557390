import { createTheme } from '@mui/material'

export const theme = createTheme({
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: { paddingTop: `5px !important` },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundImage: 'linear-gradient(to right,#8d0e2f,#bb133e)',
          color: 'white',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          color: '#002147',
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: '#760c27',
          '.Mui-checked.Mui-checked + &': {
            // Controls checked color for the track
            opacity: 0.9,
            backgroundColor: '#760c27',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: '#760c27',
        },
        colorPrimary: '#760c27',
        root: {
          backgroundColor: 'gray',
          height: '10px',
          borderRadius: 5
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#002147',
    },
    secondary: {
      main: '#760c27',
    },
    text: {
      primary: '#002147',
      secondary: 'rgba(0,33,71,0.54)',
      disabled: 'rgba(0,33,71,0.38)',
    },
  },
})
