import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAuthContext } from '../../../../context/context'

type CreateTimerDialogProps = {
  createDialogOpen: boolean
  handleCreateDialogOpen: () => void
  handleCreateDialogClose: () => void
  createDialog: Timer
  setCreateDialog: React.Dispatch<React.SetStateAction<Timer>>
  handleCreateDialogSubmit: () => void
  handleDialogChange: (
    prop: keyof Timer,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CreateTimerDialog = ({
  createDialogOpen,
  handleCreateDialogOpen,
  handleCreateDialogClose,
  createDialog,
  setCreateDialog,
  handleDialogChange,
  handleCreateDialogSubmit,
}: CreateTimerDialogProps) => {
  const { makeRequest, user, token } = useAuthContext()

  const MAX_MESSAGES = 3
  const [messages, setMessages] = useState<TimerMessage[]>([
    {
      text: 'Message #1',
    },
  ])
  // Stores commands that user can choose from
  const [commands, setCommands] = useState<Command[]>([])
  const [disableInput, setDisabledInput] = useState<boolean>(
    createDialog.commands.length >= 3,
  )

  // Handles array of messages user wants to add to timer
  const addMessage = () => {
    if (messages.length > MAX_MESSAGES) return
    setMessages([...messages, { text: `Message #${messages.length + 1}` }])
  }

  useEffect(() => {
    makeRequest('/modules/commands', 'GET', token)
      .then((data: any) => data)
      .then((json: any) => {
        const parsed = JSON.parse(json)
        const createdRows: Command[] = []
        for (let i = 0; i < parsed.data.length; i++) {
          createdRows.push({
            id: parsed.data[i].id,
            name: parsed.data[i].name,
            aliases: parsed.data[i].aliases,
            response: parsed.data[i].response,
            response_type: parsed.data[i].response_type,
            enabled: parsed.data[i].enabled,
            enabled_offline: parsed.data[i].enabled_offline,
            enabled_online: parsed.data[i].enabled_online,
            global_cooldown: parsed.data[i].global_cooldown,
            user_cooldown: parsed.data[i].user_cooldown,
            channel_id: parsed.data[i].channel_id,
            created_at: parsed.data[i].created_at,
            updated_at: parsed.data[i].updated_at,
            useage_count: parsed.data[i].useage_count,
          })
        }

        setCommands(createdRows)
      })
  }, [token])

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={createDialogOpen}
        onClose={handleCreateDialogClose}
      >
        <Stack direction="row">
          <Grid container alignItems="center">
            <Grid>
              <DialogTitle>Create a timer</DialogTitle>
            </Grid>
            <Grid sx={{ marginLeft: 'auto' }}>
              <Box>
                <FormControlLabel
                  value="end"
                  control={<Checkbox checked={createDialog.enabled} />}
                  onChange={() => {
                    setCreateDialog({
                      ...createDialog,
                      enabled: !createDialog.enabled,
                    })
                  }}
                  label="Enabled"
                  labelPlacement="end"
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <DialogContent>
          <Stack direction={'column'} spacing={2}>
            {/* Basic Timer settings */}
            <Stack spacing={1.5}>
              <TextField
                error={createDialog.name.length > 255 ? true : false}
                helperText={
                  createDialog.name.length > 255
                    ? 'Name is over 255 characters'
                    : ''
                }
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                defaultValue={createDialog.name}
                onInput={handleDialogChange('name')}
                type="text"
                fullWidth
                variant="outlined"
              />

              <Stack direction="row" spacing={16}>
                <Box>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox checked={createDialog.enabled_online} />}
                    onChange={() => {
                      setCreateDialog({
                        ...createDialog,
                        enabled_online: !createDialog.enabled_online,
                      })
                    }}
                    label="Enabled online"
                    labelPlacement="end"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox checked={createDialog.enabled_offline} />
                    }
                    onChange={() => {
                      setCreateDialog({
                        ...createDialog,
                        enabled_offline: !createDialog.enabled_offline,
                      })
                    }}
                    label="Enabled offline"
                    labelPlacement="end"
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Box>
                  <OutlinedInput
                    id="timer-online-interval"
                    value={createDialog.online_interval}
                    onChange={handleDialogChange('online_interval')}
                    endAdornment={
                      <InputAdornment position="end">minutes</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                  <FormHelperText id="outlined-weight-helper-text">
                    Execution frequency when stream is online
                  </FormHelperText>
                </Box>

                <Box>
                  <OutlinedInput
                    id="timer-offline-interval"
                    value={createDialog.offline_interval}
                    onChange={handleDialogChange('offline_interval')}
                    endAdornment={
                      <InputAdornment position="end">minutes</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                  <FormHelperText id="outlined-weight-helper-text">
                    Execution frequency when stream is offline
                  </FormHelperText>
                </Box>
              </Stack>
            </Stack>

            <Divider />

            {/* Message responses */}
            <Stack>
              <Box>
                <Grid container alignItems="center">
                  <Grid>
                    <Stack>
                      <Typography variant="h6">Messages</Typography>
                      <Typography>Up to 3 messages</Typography>
                    </Stack>
                  </Grid>
                  <Grid sx={{ marginLeft: 'auto' }}>
                    <Button
                      variant="contained"
                      disabled={messages.length > MAX_MESSAGES ? true : false}
                      onClick={addMessage}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <Stack direction="column">
                  {/* Message box with delete button */}
                  {messages.map((msg, ind) => (
                    <Stack direction="row" spacing={2}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label={'Message #' + (ind + 1)}
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                          console.log(e.target.value)
                          messages[ind] = { text: e.target.value }
                          setCreateDialog({
                            ...createDialog,
                            messages: messages,
                          })
                        }}
                      />
                      <Box>
                        <IconButton
                          sx={{
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                          aria-label="delete"
                          onClick={() => {
                            // Remove message from array by index
                            setMessages((msgs) =>
                              msgs.filter((_, index) => index !== ind),
                            )
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Stack>

            <Divider />

            {/* Command responses */}
            <Stack spacing={2}>
              <Box>
                <Typography variant="h6">Commands</Typography>
                <Typography>Up to 3 commands</Typography>
                <Stack direction="column">
                  {/* Message box with delete button */}
                  <Autocomplete
                    multiple
                    id="timer-commands"
                    options={commands}
                    getOptionLabel={(option) =>
                      `${user.bot.prefix}${option.name}`
                    }
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.name}
                          {...getTagProps({ index })}
                          // Set disable explicitly after getTagProps
                          disabled={disableInput}
                        />
                      ))
                    }
                    onChange={(e, value, situation, option) => {
                      switch (situation) {
                        case 'selectOption':
                          if (createDialog.commands.length >= 3) {
                            return
                          }
                          // Append to form values
                          if (createDialog.commands == null) {
                            setCreateDialog({
                              ...createDialog,
                              commands: [
                                {
                                  id: 0,
                                  timer_id: createDialog.id,
                                  command_id: option?.option.id,
                                },
                              ],
                            })
                          } else {
                            setCreateDialog({
                              ...createDialog,
                              commands: [
                                ...createDialog.commands,
                                {
                                  id: 0,
                                  timer_id: createDialog.id,
                                  command_id: option?.option.id,
                                },
                              ],
                            })
                          }
                          setDisabledInput(createDialog.commands.length >= 3)
                          break

                        case 'removeOption':
                          setCreateDialog({
                            ...createDialog,
                            commands: [
                              ...createDialog.commands.filter((cmd) => {
                                return cmd.id !== option!.option.id
                              }),
                            ],
                          })
                          setDisabledInput(createDialog.commands.length >= 3)
                          break

                        case 'clear':
                          setCreateDialog({
                            ...createDialog,
                            commands: [],
                          })
                          setDisabledInput(createDialog.commands.length >= 3)
                          break

                        default:
                          break
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Commands"
                        placeholder="Favorites"
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose}>Cancel</Button>
          <Button onClick={handleCreateDialogSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateTimerDialog
