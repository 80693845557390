import { useTheme } from '@mui/material/styles'
import { globalConfig } from '../../../../../context/global'

type TwitchChatProps = {
  channel: string | undefined
}

const TwitchChat = ({ channel }: TwitchChatProps) => {
  const parent = globalConfig.env === 'development' ? 'localhost' : 'usobot.org'
  const theme = useTheme()
  return (
    <iframe
      title="twitch-chat-embed"
      style={{ width: '100%', height: '100%', border: 'none' }}
      src={
        theme.palette.mode === 'dark'
          ? `https://www.twitch.tv/embed/${channel}/chat?parent=${parent}&darkpopout`
          : `https://www.twitch.tv/embed/${channel}/chat?parent=${parent}`
      }
    />
  )
}

export default TwitchChat
