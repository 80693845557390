import { createContext, useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { globalConfig } from './global'

const initialState: any = {
  loggedIn: false,
  user: null,
}

export const AppContext = createContext(initialState)

const AppProvider = (props: any) => {
  const [cookies, , removeCookie] = useCookies()
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [user, setUser] = useState<OverallAccount | null>(null)
  const [campaign, setCampaign] = useState<Campaign | null>(null)
  const [token, setToken] = useState<string>(
    cookies['uso-token'] !== undefined ? cookies['uso-token'] : '',
  )

  /* 
    Steps to making authenticated request
    1. Check cookie. 
    2. If undefined, log out user
    3. Otherwise check if JWT is valid. If it isn't, refresh it otherwise just do the request
  */

  const makeRequest = (
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    jwt: string,
    body?: any,
  ) => {
    const makeReq = (jwtToken?: string) => {
      let toFetch =
        body === null
          ? fetch(`${globalConfig.baseUrl}${endpoint}`, {
              method: method,
              credentials: 'include',
              headers: {
                Authorization: 'Bearer ' + jwtToken,
              },
            })
          : fetch(`${globalConfig.baseUrl}${endpoint}`, {
              method: method,
              credentials: 'include',
              headers: {
                Authorization: 'Bearer ' + jwtToken,
              },
              body: body,
            })

      return toFetch
        .then((res: any) => {
          if (res.data) {
            return res.json()
          } else {
            return res.text()
          }
        })
        .then((json) => {
          return json
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (token !== undefined) {
      try {
        // Check if JWT is valid
        const payload = JSON.parse(atob(token.split('.')[1]))
        if (payload.exp * 1000 < Date.now()) {
          // console.log('THIS TOKEN HAS expired')
          return refreshJWT().then((refreshed) => {
            if (refreshed !== undefined) {
              // console.log('HAS REFRESHED?', refreshed.hasRefreshed, refreshed.jwt)
              if (refreshed.hasRefreshed) {
                // console.log('RETURNED HERE')
                setToken(refreshed.jwt)
                return makeReq(refreshed.jwt)
              }
            } else {
              // TODO: ????
              setUser(null)
              setCampaign(null)
              setToken('')
              removeCookie('uso-token')
              return
            }
          })
        } else {
          return makeReq(token!)
        }
      } catch (err) {
        setLoggedIn(false)
        setUser(null)
        setCampaign(null)
        setToken('')
        removeCookie('uso-token')
        return
      }
    } else {
      setUser(null)
    }
  }

  // returns true if refreshed, otherwise false
  const refreshJWT = () => {
    const toReturn = {
      hasRefreshed: false,
      jwt: '',
    }
    return fetch(`${globalConfig.baseUrl}/refresh`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((res: any) => {
        if (res.data) {
          return res.json()
        } else {
          return res.text()
        }
      })
      .then((data) => {
        const res: APIRequest = JSON.parse(data)
        if (res.error) {
          return toReturn
        }

        if (res.data) {
          toReturn.hasRefreshed = true
          toReturn.jwt = res.data
          return toReturn
        }
      })
  }

  useEffect(() => {
    if (token !== undefined) {
      makeRequest('/accounts/@me', 'GET', token, null)
        ?.then((data) => data)
        .then((json) => {
          const parsed = JSON.parse(json)
          setUser(parsed.data)

          if (parsed.data?.account.fundraiser_url !== 'none') {
            makeRequest(
              '/tiltify/fundraiser',
              'POST',
              token,
              JSON.stringify({
                type: 'campaign',
                url: parsed.data?.account.fundraiser_url,
              }),
            )
              ?.then((data: any) => data)
              .then((json: any) => {
                const parsed = JSON.parse(json)
                if (parsed.error) {
                  setCampaign(null)
                  localStorage.removeItem('tiltify_public_id')
                  return
                }
                setCampaign(parsed.data)
                localStorage.setItem('tiltify_public_id', parsed.data.publicId)
              })
          }

          setLoggedIn(true)
        })
    } else {
      // Couldn't refresh, sign user out.
      setLoggedIn(false)
      return
    }
  }, [cookies['uso-token'], token])

  return (
    <AppContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        makeRequest,
        campaign,
        setCampaign,
        token,
        setToken,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export const useAuthContext = () => useContext(AppContext)

export default AppProvider
