/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CreateTimerDialog from './CreateTimerDialog'
import EditIcon from '@mui/icons-material/Edit'
import NoRowsOverlay from '../../../NoRowsOverlay'
import { useAuthContext } from '../../../../context/context'
import EditTimerDialog from './EditTimerDialog'
import getTheme from '../../../../utils/getTheme'
import { useSnackbar } from 'notistack'

const Timers = () => {
  const theme = getTheme()

  const { enqueueSnackbar } = useSnackbar()

  const { user, makeRequest, token } = useAuthContext()
  // Check if data is done loading
  const [loading, setLoading] = useState<boolean>(true)
  // Rows of commands for data grid
  const [rows, setRows] = useState<Timer[] | null>(null)

  /* Create Timer dialog */
  // Handles opening and closing of the timer create dialog box
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [editDialog, setEditDialog] = useState<Timer | null>(null)

  // Handles the form data for creating a timer
  const defaultTimerValues: Timer = {
    channel_id: user?.account.channel_id,
    commands: [],
    messages: [{ text: 'Message 1' }],
    enabled: true,
    enabled_offline: true,
    enabled_online: true,
    name: 'Timer',
    offline_interval: 5,
    online_interval: 5,
    id: 0,
  }
  const [createDialog, setCreateDialog] = useState<Timer>(defaultTimerValues)
  const defaults = () => {
    setCreateDialog(defaultTimerValues)
  }

  const handleDialogChange = (prop: keyof Timer) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (prop === 'online_interval' || prop === 'offline_interval') {
      setCreateDialog({ ...createDialog, [prop]: parseInt(event.target.value) })
      return
    }
    setCreateDialog({ ...createDialog, [prop]: event.target.value })
  }

  const handleCreateDialogOpen = () => {
    setCreateDialogOpen(true)
  }

  const handleCreateDialogClose = () => {
    setCreateDialog(defaultTimerValues)
    setCreateDialogOpen(false)
    defaults()
  }

  const handleCreateDialogSubmit = () => {
    makeRequest('/modules/timers', 'POST', token, JSON.stringify(createDialog))
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          enqueueSnackbar(
            'There was an error creating a new timer. ' + parsed.error,
            {
              variant: 'error',
            },
          )
        }

        if (parsed.data) {
          const newTimer = {
            channel_id: user?.account.channel_id,
            commands: parsed.data.commands,
            enabled: parsed.data.enabled,
            enabled_offline: parsed.data.enabled_offline,
            enabled_online: parsed.data.enabled_online,
            id: parsed.data.id,
            messages: parsed.data.messages,
            name: parsed.data.name,
            offline_interval: parsed.data.offline_interval,
            online_interval: parsed.data.online_interval,
          }

          if (rows === null) {
            setRows([newTimer])
          } else {
            setRows([...rows, newTimer])
          }

          enqueueSnackbar('Created a timer: ' + parsed.data.name, {
            variant: 'success',
          })
        }
      })

    handleCreateDialogClose()
    defaults()
  }

  useEffect(() => {
    makeRequest('/modules/timers', 'GET', token, null)
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          console.log(parsed.error)
          return
        }

        if (parsed.data) {
          const createdRows: Timer[] | null = parsed.data
          setRows(createdRows)
        }

        setLoading(false)
      })
  }, [token])

  // Creates empty header for column
  const EmptyHeaderTitle = () => {
    return <></>
  }

  // Makes the edit column have a button instead of text
  const EditCell = (props: GridRenderCellParams) => {
    // Get the row where the edit button was so they can begin editing
    const { row } = props

    return (
      <Button
        startIcon={<EditIcon />}
        variant="contained"
        onClick={() => {
          setEditDialog(row)
          setEditDialogOpen(true)
        }}
        sx={{
          backgroundImage:
            theme === 'light'
              ? 'linear-gradient(to right,#8d0e2f,#bb133e)'
              : 'linear-gradient(to right,#001730,#002147)',
          color: 'white',
        }}
      >
        Edit
      </Button>
    )
  }

  const MessagesCell = (props: GridRenderCellParams) => {
    const { row } = props
    var toRender: string = ''

    if (row.messages !== null && row.messages.length > 0) {
      toRender =
        toRender +
        ` ${row.messages.length} ${
          row.messages.length === 1 ? 'message' : 'messages'
        }`
    }

    if (row.commands !== null && row.commands.length > 0) {
      toRender =
        toRender +
        ` ${row.commands.length} ${
          row.commands.length === 1 ? 'command' : 'commands'
        }`
    }

    return <Typography>{toRender}</Typography>
  }

  const OnlineIntervalCell = (props: GridRenderCellParams) => {
    const { row } = props

    return (
      <Typography>
        {row.online_interval} {row.online_interval === 1 ? 'minute' : 'minutes'}
      </Typography>
    )
  }

  const OfflineIntervalCell = (props: GridRenderCellParams) => {
    const { row } = props

    return (
      <Typography>
        {row.offline_interval}{' '}
        {row.offline_interval === 1 ? 'minute' : 'minutes'}
      </Typography>
    )
  }

  const columnsTest: GridColDef[] = [
    { field: 'id', hide: true },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'messages',
      headerName: 'Responses',
      flex: 1,
      renderCell: MessagesCell,
    },
    {
      field: 'online_interval',
      headerName: 'Online Interval',
      flex: 1,
      renderCell: OnlineIntervalCell,
    },
    {
      field: 'offline_interval',
      headerName: 'Offline Interval',
      width: 150,
      flex: 1,
      renderCell: OfflineIntervalCell,
    },
    {
      field: 'edit',
      renderCell: EditCell,
      renderHeader: EmptyHeaderTitle,
      flex: 1,
      editable: false,
      sortable: false,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <CreateTimerDialog
        createDialogOpen={createDialogOpen}
        handleCreateDialogOpen={handleCreateDialogOpen}
        handleCreateDialogClose={handleCreateDialogClose}
        createDialog={createDialog}
        setCreateDialog={setCreateDialog}
        handleDialogChange={handleDialogChange}
        handleCreateDialogSubmit={handleCreateDialogSubmit}
      />
      <EditTimerDialog
        editDialogOpen={editDialogOpen}
        setEditDialogOpen={setEditDialogOpen}
        editDialog={editDialog}
        user={user}
        jwtToken={token}
        makeRequest={makeRequest}
        rows={rows}
        setRows={setRows}
        setEditDialog={setEditDialog}
      />
      <Stack direction={'row'} spacing={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreateDialogOpen}
        >
          Create
        </Button>
      </Stack>

      <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid
          key={'commands'}
          rows={rows !== null ? rows : []}
          columns={columnsTest}
          pageSize={20}
          rowsPerPageOptions={[15]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          disableColumnMenu
          components={{
            NoRowsOverlay: NoRowsOverlay,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          /* Remove the MUI Datagrid dividers */
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          loading={loading}
        />
      </div>
    </Box>
  )
}

export default Timers
