class Global {
  env: string
  baseUrl: string

  constructor(envGiven: string) {
    this.env = envGiven
    this.baseUrl =
      envGiven === 'development'
        ? 'http://localhost:8000'
        : 'https://api.usobot.org'
  }
}

export const globalConfig = new Global('production')