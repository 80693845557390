/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Skeleton, Switch } from "@mui/material"
import { GridColDef, DataGrid, GridToolbarQuickFilter, GridRenderCellParams } from "@mui/x-data-grid"
import React, { useState } from "react"
import CreateCommandForm from "./DialogueButtons"
import EditIcon from "@mui/icons-material/Edit"
import CommandEditDialog from "./Dialogue/Edit"
import NoRowsOverlay from "../../../NoRowsOverlay"
import { useAuthContext } from "../../../../context/context"
import getTheme from "../../../../utils/getTheme"

interface CommandsProps {
	snackbar: SnackbarProps
	setSnackbar: React.Dispatch<React.SetStateAction<SnackbarProps>>
}

function QuickSearchToolbar() {
	return (
		<Box
			sx={{
				p: 1,
				pb: 0,
				width: "auto",
			}}
		>
			<GridToolbarQuickFilter
				sx={{ width: "100%" }}
				size="medium"
				quickFilterParser={(searchInput: string) =>
					searchInput
						.split(",")
						.map((value) => value.trim())
						.filter((value) => value !== "")
				}
			/>
		</Box>
	)
}

const Commands = ({ snackbar, setSnackbar }: CommandsProps) => {
	const theme = getTheme()
	const { user, makeRequest, token } = useAuthContext()
	// Check if data is done loading
	const [loading, setLoading] = useState<boolean>(true)
	// Rows of commands for data grid
	const [rows, setRows] = useState<Command[]>([])
	// Displays dialog for when user is editing a command
	const [commandEditDialog, setCommandEditDialog] = useState<Command>({
		enabled: true,
		enabled_offline: true,
		enabled_online: true,
		global_cooldown: 5,
		user_cooldown: 10,
		channel_id: user?.account.channel_id,
		created_at: "",
		updated_at: "",
		useage_count: 0,
		aliases: [{ alias: "", command_id: 0, id: 0 }],
		id: 0,
		name: "",
		response: "",
		response_type: "say",
	})

	const [cmdEditDialogOpen, setCmdEditDialogOpen] = useState<boolean>(false)
	// For the Enabled column. This keeps track if command has been updated before they can toggle command again

	React.useEffect(() => {
		makeRequest("/modules/commands", "GET", token)
			.then((data: any) => data)
			.then((json: any) => {
				const parsed: APIRequest = JSON.parse(json)

				if (parsed.error) {
					console.log(parsed.error)
					return
				}

				if (parsed.data) {
					const createdRows: Command[] = []
					for (let i = 0; i < parsed.data.length; i++) {
						createdRows.push({
							id: parsed.data[i].id,
							name: parsed.data[i].name,
							aliases: parsed.data[i].aliases,
							response: parsed.data[i].response,
							response_type: parsed.data[i].response_type,
							enabled: parsed.data[i].enabled,
							enabled_offline: parsed.data[i].enabled_offline,
							enabled_online: parsed.data[i].enabled_online,
							global_cooldown: parsed.data[i].global_cooldown,
							user_cooldown: parsed.data[i].user_cooldown,
							channel_id: parsed.data[i].channel_id,
							created_at: parsed.data[i].created_at,
							updated_at: parsed.data[i].updated_at,
							useage_count: parsed.data[i].useage_count,
						})
					}

					setRows(createdRows)
					setLoading(false)
				}
			})
	}, [token])

	// When the 'Enabled' column is toggled it'll be updated here
	const handleEnabledButtonClicked = (event: React.ChangeEvent<HTMLInputElement>, oldValue: boolean, cmd: Command) => {
		const idx = rows.findIndex((row) => row.id === cmd.id)
		if (idx === -1) return

		// Update the local command in datagrid
		const newValue = !oldValue

		const updatedRow = rows[idx]

		const body = {
			channel_id: user?.account.channel_id,
			name: updatedRow.name,
			aliases: updatedRow.aliases,
			response: updatedRow.response,
			response_type: updatedRow.response_type,
			enabled: newValue,
			enabled_offline: updatedRow.enabled_offline,
			enabled_online: updatedRow.enabled_online,
			global_cooldown: updatedRow.global_cooldown,
			user_cooldown: updatedRow.user_cooldown,
			created_at: updatedRow.created_at,
			updated_at: updatedRow.updated_at,
			id: updatedRow.id,
		}

		makeRequest("/modules/commands", "PUT", token, JSON.stringify(body))
			.then((data: any) => data)
			.then((json: any) => {
				const parsed: APIRequest = JSON.parse(json)

				if (parsed.error) {
					console.log("Error updating a command", parsed.error)
					return
				}

				if (parsed.data) {
					setRows(
						rows.map((item) =>
							item.id === parsed.data.id
								? {
										...item,
										channel_id: parsed.data.channel_id,
										name: parsed.data.name,
										aliases: parsed.data.aliases,
										response: parsed.data.response,
										response_type: parsed.data.response_type,
										enabled: parsed.data.enabled,
										enabled_offline: parsed.data.enabled_offline,
										enabled_online: parsed.data.enabled_online,
										global_cooldown: parsed.data.global_cooldown,
										user_cooldown: parsed.data.user_cooldown,
										created_at: parsed.data.created_at,
										updated_at: parsed.data.updated_at,
										id: parsed.data.id,
								  }
								: item
						)
					)
				}
			})
	}

	const handleEditButtonClick = (row: Command) => {
		setCmdEditDialogOpen(true)
		setCommandEditDialog(row)
	}

	// Makes the enabled column have switches instead of text
	const EnabledCell = (props: GridRenderCellParams) => {
		let { value, row } = props

		return (
			<Switch
				checked={value}
				onChange={(event) => {
					handleEnabledButtonClicked(event, value, row)
				}}
				inputProps={{ "aria-label": "controlled" }}
			/>
		)
	}

	// Creates empty header for column
	const EmptyHeaderTitle = () => {
		return <></>
	}

	// Makes the edit column have a button instead of text
	const EditCell = (props: GridRenderCellParams) => {
		// Get the row where the edit button was so they can begin editing
		const { row } = props

		return (
			<Button
				startIcon={<EditIcon />}
				variant="contained"
				onClick={() => handleEditButtonClick(row)}
				sx={{
					backgroundImage: theme === "light" ? "linear-gradient(to right,#8d0e2f,#bb133e)" : "linear-gradient(to right,#001730,#002147)",
					color: "white",
				}}
			>
				Edit
			</Button>
		)
	}

	const columns: GridColDef[] = [
		{ field: "id", hide: true },
		{ field: "name", headerName: "Name", width: 200 },
		{ field: "response", colSpan: 2, headerName: "Response", flex: 1 },
		{ field: "spacer", width: 400, renderHeader: EmptyHeaderTitle },
		{
			field: "enabled",
			headerName: "Enabled",
			width: 100,
			renderCell: EnabledCell,
			sortable: false,
		},
		{
			field: "edit",
			renderCell: EditCell,
			renderHeader: EmptyHeaderTitle,
			editable: false,
			sortable: false,
		},
	]

	return (
		<Box sx={{ width: "100%", height: "80vh" }}>
			<Box>
				<CreateCommandForm setSnackbar={setSnackbar} snackbar={snackbar} jwtToken={token} rows={rows} setRows={setRows} />
			</Box>
			<CommandEditDialog commandEditDialog={commandEditDialog} setCommandEditDialog={setCommandEditDialog} jwtToken={token} rows={rows} setRows={setRows} user={user} cmdEditDialogOpen={cmdEditDialogOpen} setCmdEditDialogOpen={setCmdEditDialogOpen} />
			<DataGrid
				key={"commands"}
				rows={rows}
				columns={columns}
				pageSize={20}
				rowsPerPageOptions={[15]}
				disableColumnFilter
				disableColumnSelector
				disableDensitySelector
				disableSelectionOnClick
				disableColumnMenu
				components={{
					LoadingOverlay: LoadingSkeleton,
					Toolbar: QuickSearchToolbar,
					NoRowsOverlay: NoRowsOverlay,
				}}
				componentsProps={{
					toolbar: {
						showQuickFilter: true,
						quickFilterProps: { debounceMs: 500 },
					},
				}}
				/* Remove the MUI Datagrid dividers */
				sx={{
					".MuiDataGrid-columnSeparator": {
						display: "none",
					},
					"&.MuiDataGrid-root": {
						border: "none",
					},
					"&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
						outline: "none",
					},
				}}
				loading={loading}
			/>
		</Box>
	)
}

// Skeleton data is used while command data is loading.
const LoadingSkeleton = () => (
	<Box
		sx={{
			height: "max-content",
		}}
	>
		{[...Array(10)].map((_, index: number) => (
			<Skeleton key={index} variant="rectangular" sx={{ my: 3, mx: 3 }} />
		))}
	</Box>
)

export default Commands
