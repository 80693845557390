const getTheme = () => {
  const stored = localStorage.getItem('theme')
  if (stored !== null) {
    return stored as 'light' | 'dark'
  }

  return 'light'
}

export default getTheme
