/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
} from '@mui/material'
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid'
import { useAuthContext } from '../../../../../context/context'
import TiltifyPrompt from './TiltifyPrompt'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
// import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
// import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'

// Creates empty header for column
const EmptyHeaderTitle = () => {
  return <></>
}

// Formats currency symbol in front of the amount donated
const AmountCell = (props: GridRenderCellParams) => {
  const { row } = props

  return <>${row.amount}</>
}

const UsernameCell = (props: GridRenderCellParams) => {
  const { row } = props

  return <strong>{row.donorName}</strong>
}

const CompletedAtCell = (props: GridRenderCellParams) => {
  const { row } = props
  if (typeof row.completedAt === 'string') {
    return row.completedAt
  } else {
    return row.completedAt.toISOString()
  }
}

const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': { display: 'none' },
  '& .MuiDataGrid-virtualScroller': { marginTop: '0!important' },
}))

type TiltifyDonationsProps = {
  wsConnected: boolean
  topDonation: TopDonation2 | null
  donations: any
  pageSize: number
  loading: boolean
  handlePageSizeChange: (event: SelectChangeEvent) => void
}

const TiltifyDonations = ({
  wsConnected,
  topDonation,
  donations,
  pageSize,
  loading,
  handlePageSizeChange,
}: TiltifyDonationsProps) => {
  const { campaign } = useAuthContext()

  const columnsDonations: GridColDef[] = [
    { field: 'id', hide: true },
    {
      field: 'completedAt',
      hide: true,
      flex: 0.4,
      renderCell: CompletedAtCell,
    },
    {
      field: 'donorName',
      flex: 0.25,
      minWidth: 150,
      renderHeader: EmptyHeaderTitle,
      renderCell: UsernameCell,
    },
    {
      field: 'amount',
      flex: 0.15,
      minWidth: 50,
      renderHeader: EmptyHeaderTitle,
      renderCell: AmountCell,
    },
    {
      field: 'donorComment',
      flex: 1,
      renderHeader: EmptyHeaderTitle,
    },
  ]

  // Returns element that creates a new donation
  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      {campaign !== null && campaign.name !== 'none' ? (
        <div style={{ display: 'flex', height: '90%' }}>
          <div style={{ flexGrow: 1 }}>
            <>
              <Stack direction="row" spacing={2} sx={{ m: 1.3 }}>
                <Box sx={{ width: '90%' }}>
                  <Alert
                    severity="info"
                    iconMapping={{
                      info: <EmojiEventsIcon fontSize="inherit" />,
                    }}
                  >
                    <AlertTitle>
                      Top Donor | {topDonation?.donorName} $
                      {topDonation?.amount}
                    </AlertTitle>
                    {topDonation?.donorComment !== null
                      ? topDonation?.donorComment
                      : 'No message'}
                  </Alert>
                </Box>

                <Box
                  sx={{
                    direction: 'column',
                    justifyContent: 'center',
                    pt: 2.5,
                  }}
                >
                  <FormControl sx={{ width: '80px' }}>
                    <InputLabel id="pagesize-donations">Page Size</InputLabel>
                    <Select
                      labelId="pagesize-donations-select-label"
                      id="pagesize-donations-select"
                      value={pageSize.toString()}
                      label="Page Size"
                      onChange={handlePageSizeChange}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={40}>40</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Divider />
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'completedAt', sort: 'desc' }],
                      },
                    }}
                    rows={donations !== null ? donations : []}
                    columns={columnsDonations}
                    loading={loading}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    paginationMode="server"
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                    sx={{
                      padding: 1,
                      '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                      },
                      '&.MuiDataGrid-root': {
                        border: 'none',
                      },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                    }}
                  />
                </div>
              </div>
            </>
          </div>
        </div>
      ) : (
        <TiltifyPrompt />
      )}
    </Box>
  )
}

export default TiltifyDonations
