import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Slide,
  Stack,
  Box,
  Alert,
  AlertTitle,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

type AccountDialogProps = {
  accountDialogOpen: boolean
  setAccountDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AccountDialog = ({
  accountDialogOpen,
  setAccountDialogOpen,
}: AccountDialogProps) => {
  // Confirmation for deleting account
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false)

  // Closes the account setting dialog
  const handleClose = () => {
    setAccountDialogOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={accountDialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Account Settings
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack sx={{ p: 3 }}>
        <Dialog
          open={confirmationOpen}
          onClose={() => {
            setConfirmationOpen(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you'd like to delete your account?
          </DialogTitle>
          <DialogContent>
            <Typography>
              This cannot be undone, all account data will be deleted and the
              bot will part your channel.
            </Typography>
            <Typography>
              This does not disconnect your Twitch account from USO Bot. If
              you'd like to do so, go to your /settings/connections page and
              click Disconnect next to "USOBot".
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmationOpen(false)
              }}
            >
              Disagree
            </Button>
            <Button
              onClick={() => {
                setConfirmationOpen(false)
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Box>
          <Alert severity="warning">
            <Stack spacing={2}>
              <Box>
                <AlertTitle>Delete Account</AlertTitle>
                Deleting your account will delete all data associated with your
                account and the bot will leave your channel.
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    setConfirmationOpen(true)
                  }}
                >
                  Delete Account
                </Button>
              </Box>
            </Stack>
          </Alert>
        </Box>
      </Stack>
    </Dialog>
  )
}

export default AccountDialog
