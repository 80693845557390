import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useAuthContext } from '../../../../../context/context'

// Campaigns are ran by users usually for a cause
// Events are ran by the causes

// This is what pops up in tiltify campaign box if they don't have a tiltify campaign set
const TiltifyPrompt = () => {
  const { user, makeRequest, setCampaign, token } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  })
  const [url, setUrl] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const handleInputChange = (e: any) => {
    const { value } = e.target
    setUrl(value)
    setError({
      error: false,
      message: '',
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    makeRequest(
      '/tiltify/fundraiser',
      'POST',
      token,
      JSON.stringify({
        type: 'campaign',
        url: url,
      }),
    )
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          // Alert user that there was an error
          console.log('ERROR ', parsed.error)
          if (parsed.error === 'invalid url') {
            setError({
              error: true,
              message: 'Invalid Tiltify URL.',
            })
            enqueueSnackbar('Invalid Tiltify URL.', {
              variant: 'error',
            })
          } else if (parsed.error === "couldn't find a campaign by that url") {
            setError({
              error: true,
              message: `Couldn't find a fundraiser by that URL.`,
            })
            enqueueSnackbar("Couldn't find a fundraiser by that URL.", {
              variant: 'error',
            })
          }
        } else {
          if (parsed.data) {
            setCampaign(parsed.data)

            makeRequest(
              '/accounts/@me',
              'PUT',
              token,
              JSON.stringify({
                channel_id: user.account.channel_id,
                fundraiser_url: url,
              }),
            )
              .then((data: any) => data)
              .then((json: any) => {
                console.log(json)
                const parsed: APIRequest = JSON.parse(json)
                if (parsed.error) {
                  // Alert user that there was an error
                  console.log('ERROR ', parsed.error)
                } else {
                  console.log(json)
                }
              })
          }
        }
      })
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        pt: 4,
      }}
    >
      <Stack spacing={1}>
        <Typography typography={''}>
          Enter your Tiltify fundraiser to get started...
        </Typography>
        <Box>
          <Button variant="contained" onClick={handleClickOpen}>
            ADD FUNDRAISER
          </Button>
        </Box>
      </Stack>

      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Keep track of your fundraiser</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Fundraiser URL"
            type="email"
            fullWidth
            variant="standard"
            required
            helperText={error.error ? `Error: ${error.message}` : ''}
            onChange={handleInputChange}
            error={error.error ? true : false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={url.length === 0 ? true : false}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TiltifyPrompt
