import { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { useAuthContext } from '../../../../context/context'
import { withSize } from 'react-sizeme'
import {
  Box,
  Card,
  IconButton,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import WidgetsMenu from './WidgetsMenu'
import {
  SnackbarMessage,
  OptionsObject,
  SnackbarKey,
  useSnackbar,
} from 'notistack'
import { theme } from '../../../../theme'
import CloseIcon from '@mui/icons-material/Close'
import makeStyles from '@mui/styles/makeStyles'
import BoxBot from './Widgets/Bot'
import Tiltify from './Widgets/Tiltify'
import TiltifyDonations from './Widgets/TiltifyDonations'
import TiltifyPrompt from './Widgets/TiltifyPrompt'
import TwitchChat from './Widgets/TwitchChat'
import TwitchChatTheUSO from './Widgets/TwitchChatTheUSO'

type StatusProps = {
  user: OverallAccount | null
  size: {
    width: any
  }
  open: boolean
}
const ResponsiveGridLayout = WidthProvider(Responsive)
const originalItems = ['a', 'b', 'c', 'd', 'e']
const initialLayouts = {
  lg: [
    {
      i: 'a',
      x: 0,
      y: 11,
      w: 4,
      h: 4,
      minW: 3,
      minH: 4,
    },
    { i: 'b', x: 0, y: 0, w: 20, h: 3 },
    { i: 'c', x: 0, y: 3, w: 4, h: 8 },
    { i: 'd', x: 0, y: 4, w: 4, h: 4 },
    { i: 'e', x: 0, y: 5, w: 5, h: 5 },
  ],
}

export const widgetNames: any = {
  a: 'Bot',
  b: 'Campaign',
  c: 'Twitch Chat',
  d: 'Donations',
  e: 'Twitch Chat | TheUSO',
}

function tiltifyWebSocket(publicId: string) {
  let client: any
  let isConnected = false
  let reconnectOnClose = true
  let messageListeners: any = []
  let stateChangeListeners: any = []
  let nonce = 0

  function on(fn: any) {
    messageListeners.push(fn)
  }

  function off(fn: any) {
    messageListeners = messageListeners.filter((l: any) => l !== fn)
  }

  function onStateChange(fn: any) {
    stateChangeListeners.push(fn)
    return () => {
      stateChangeListeners = stateChangeListeners.filter((l: any) => l !== fn)
    }
  }

  function start() {
    client = new WebSocket(
      'wss://websockets.tiltify.com/socket/websocket?vsn=2.0.0',
    )

    function send(client: WebSocket | null, args: any, isHeartbeat: boolean) {
      if (client !== null) {
        nonce++
        const data = [
          isHeartbeat ? null : nonce.toString(),
          nonce.toString(),
          ...args,
        ]
        client.send(JSON.stringify(data))
      }
    }

    client.onopen = () => {
      if (publicId === '') {
        console.error(
          "Couldn't start Tiltify WebSocket due to publicId being empty",
        )
        reconnectOnClose = false
        return
      }
      isConnected = true
      stateChangeListeners.forEach((fn: any) => fn(true))

      send(client, [`campaign.${publicId}.donation`, 'phx_join', {}], false)
      setInterval(() => {
        const data = ['phoenix', 'heartbeat', {}]
        send(client, data, true)
      }, 1000 * 30)
    }

    const close = client.close

    // Close without reconnecting;
    client.close = () => {
      reconnectOnClose = false
      close.call(client)
    }

    client.onmessage = (event: any) => {
      messageListeners.forEach((fn: any) => fn(event.data))
    }

    client.onerror = (e: any) => console.error(e)

    client.onclose = () => {
      isConnected = false
      stateChangeListeners.forEach((fn: any) => fn(false))

      if (!reconnectOnClose) {
        // console.log('ws closed by app')
        return
      }

      // console.log('ws closed by server')

      setTimeout(start, 3000)
    }
  }

  start()

  return {
    on,
    off,
    onStateChange,
    close: () => client.close(),
    changeFundraiser: () => client.ChangeFundraiser(),
    getClient: () => client,
    isConnected: () => isConnected,
  }
}

const getTiltifyKey = () => {
  const value = localStorage.getItem('tiltify_public_id')
  if (value !== null) {
    return value
  } else {
    return ''
  }
}

const client = tiltifyWebSocket(getTiltifyKey())

function useDonations(
  campaign: Campaign | null,
  topDonation: TopDonation2 | null,
  setTopDonation: React.Dispatch<React.SetStateAction<TopDonation2 | null>>,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
  setCampaign: any,
) {
  const [donations, setDonations] = useState<any>([])

  useEffect(() => {
    function handleDonation(donation: any) {
      const [, nonce, , type, payload] = JSON.parse(donation)
      if (nonce) return
      if (type === 'donation' && payload) {
        const toAppend: TiltifyNode2 = {
          amount: parseFloat(payload.amount).toFixed(2),
          completedAt: new Date(payload.completedAt),
          donorComment: payload.comment,
          donorName: payload.name,
          id: payload.id,
          isIncentive: payload.reward_id !== null ? true : false,
        }

        let newArray = [...donations]
        newArray.pop()
        newArray.unshift(toAppend)
        setDonations(newArray)

        if (campaign !== null) {
          if (typeof toAppend.amount === 'string') {
            setCampaign((campaign: any) => ({
              ...campaign,
              amountRaised: campaign.amountRaised + parseFloat(toAppend.amount),
            }))
          } else {
            setCampaign((campaign: any) => ({
              ...campaign,
              amountRaised: campaign.amountRaised + parseFloat(toAppend.amount),
            }))
          }
        }

        if (
          topDonation !== null &&
          parseFloat(topDonation?.amount) < parseFloat(toAppend.amount)
        ) {
          setTopDonation({
            amount: toAppend.amount,
            completedAt: toAppend.completedAt,
            donorComment: toAppend.donorComment,
            donorName: toAppend.donorName,
            id: toAppend.id,
            isIncentive: toAppend.isIncentive,
          })
          if (campaign !== null) {
            if (typeof toAppend.amount === 'string') {
              setCampaign((campaign: any) => ({
                ...campaign,
                amountRaised:
                  campaign.amountRaised + parseFloat(toAppend.amount),
              }))
            } else {
              setCampaign((campaign: any) => ({
                ...campaign,
                amountRaised:
                  campaign.amountRaised + parseFloat(toAppend.amount),
              }))
            }
          }
          enqueueSnackbar(
            `New top donation at $${toAppend.amount} from ${
              toAppend.donorName
            } - ${
              toAppend.donorComment !== null
                ? toAppend.donorComment
                : '[No message]'
            }`,
            {
              variant: 'info',
            },
          )
          return
        }
        enqueueSnackbar(
          `$${toAppend.amount} donation from ${toAppend.donorName} - ${
            toAppend.donorComment !== null
              ? toAppend.donorComment
              : '[No message]'
          }`,
          {
            variant: 'info',
          },
        )
      }
    }

    client.on(handleDonation)
    return () => client.off(handleDonation)
  }, [
    campaign,
    donations,
    enqueueSnackbar,
    setCampaign,
    setDonations,
    setTopDonation,
    topDonation,
  ])

  return { donations, setDonations }
}

// Widget styling
const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    verticalAlign: 'center',
    textAlign: 'center',
    padding: '0.5rem',
  },
  spacer: {
    flexGrow: 1,
  },
  body: {
    padding: '1rem',
    flexGrow: 1,
  },
})

const Status = ({ size: { width }, open }: StatusProps) => {
  const [wsConnected, setWsConnected] = useState<boolean>(client.isConnected())
  const classes = useStyles()

  const { makeRequest, campaign, token, setCampaign, user } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()

  // Check if donos are done loading
  const [loading, setLoading] = useState<boolean>(true)
  // Holds the top donation since it doesn't change after the first fetch
  const [topDonation, setTopDonation] = useState<TopDonation2 | null>(null)
  // Page size for limit of rows per page
  const [pageSize, setPageSize] = useState<number>(10)

  // Holds all donations
  const { donations, setDonations } = useDonations(
    campaign,
    topDonation,
    setTopDonation,
    enqueueSnackbar,
    setCampaign,
  )

  useEffect(() => {
    if (campaign !== null) {
      const url = `/tiltify/donations/gql/${campaign?.publicId}?count=${pageSize}`

      makeRequest(url, 'GET', token)
        .then((data: any) => data)
        .then((json: any) => {
          const parsed: APIRequest = JSON.parse(json)

          if (parsed.data) {
            const donoObject: TiltifyGQLDonationObject = parsed.data
            const toStore: TiltifyNode2[] = []
            donoObject.data.campaign.donations.edges.forEach((d, i) => {
              toStore.push({
                amount: d.node.amount.value,
                completedAt: new Date(d.node.completedAt),
                donorComment: d.node.donorComment,
                donorName: d.node.donorName,
                id: d.node.id,
                isIncentive: d.node.incentives !== null ? true : false,
              })
            })

            setDonations(toStore)

            setTopDonation({
              amount: donoObject.data.campaign.topDonation.amount.value,
              completedAt: donoObject.data.campaign.topDonation.completedAt,
              donorName: donoObject.data.campaign.topDonation.donorName,
              id: donoObject.data.campaign.topDonation.id,
              isIncentive:
                donoObject.data.campaign.topDonation.incentives !== null
                  ? true
                  : false,
              donorComment: donoObject.data.campaign.topDonation.donorComment,
            })
            setLoading(false)
          }
        })
    }
  }, [campaign, token, pageSize])

  const handlePageSizeChange = (event: SelectChangeEvent) => {
    setPageSize((event.target.value as unknown) as number)
  }

  useEffect(() => {
    return client.onStateChange(setWsConnected)
  }, [setWsConnected])

  const getDashboardItems = () => {
    const stored = localStorage.getItem('dashboard-layout-items')
    if (stored === null) {
      localStorage.setItem(
        'dashboard-layout-items',
        JSON.stringify(originalItems),
      )
      return originalItems
    } else {
      const stored = localStorage.getItem('dashboard-layout-items')
      if (stored !== null) {
        return JSON.parse(stored)
      } else {
        localStorage.setItem(
          'dashboard-layout-items',
          JSON.stringify(originalItems),
        )
        return originalItems
      }
    }
  }

  const [items, setItems] = useState<string[]>(getDashboardItems())

  const [layouts, setLayouts] = useState(
    getFromLS('dashboard-layout') || initialLayouts,
  )

  const onLayoutChange = (
    current: ReactGridLayout.Layout[],
    allLayouts: ReactGridLayout.Layouts,
  ) => {
    setLayouts(allLayouts)
    saveToLS('dashboard-layout', allLayouts)
  }

  const onRemoveItem = (itemId: any) => {
    setItems(items.filter((i) => i !== itemId))
    const stored = localStorage.getItem('dashboard-layout-items')
    if (stored !== null) {
      const parsed = JSON.parse(stored)
      localStorage.setItem(
        'dashboard-layout-items',
        JSON.stringify(parsed.filter((i: string) => i !== itemId)),
      )
    }
  }

  const onAddItem = (itemId: string) => {
    setItems([...items, itemId])
    const stored = localStorage.getItem('dashboard-layout-items')
    if (stored !== null) {
      const parsed = JSON.parse(stored)
      parsed.push(itemId)
      localStorage.setItem('dashboard-layout-items', JSON.stringify(parsed))
    }
  }

  const widgetBody = (key: string) => {
    switch (key) {
      case 'Bot':
        return <BoxBot />

      case 'Campaign':
        return campaign !== null ? <Tiltify /> : <TiltifyPrompt />

      case 'Twitch Chat':
        return <TwitchChat channel={user?.twitch.login} />

      case 'Donations':
        return (
          <TiltifyDonations
            donations={donations}
            handlePageSizeChange={handlePageSizeChange}
            loading={loading}
            pageSize={pageSize}
            topDonation={topDonation}
            wsConnected={wsConnected}
            key={key}
          />
        )

      case 'Twitch Chat | TheUSO':
        return <TwitchChatTheUSO />

      default:
        return <></>
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <ResponsiveGridLayout
        className="layout"
        containerPadding={[5, 5]}
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 22, md: 16, sm: 10, xs: 8, xxs: 6 }}
        rowHeight={50}
        onLayoutChange={onLayoutChange}
        width={width}
        draggableHandle=".drag-handle"
      >
        {items.map((key) => (
          <div key={key} data-grid={{ w: 3, h: 7, x: 0, y: Infinity }}>
            <Card className={classes.root}>
              <div className="drag-handle">
                <div
                  className={classes.header}
                  style={{
                    backgroundColor:
                      theme.palette.mode === 'light' ? '#0e2947' : '#343536',
                  }}
                >
                  <Stack direction="row" spacing={0.3}>
                    <Typography
                      variant="h6"
                      sx={{ lineHeight: '30px', color: 'white', pl: 0.5 }}
                    >
                      {widgetNames[key] as string}
                    </Typography>

                    {widgetNames[key] === 'Campaign' ? (
                      <Typography
                        variant="h6"
                        sx={{ lineHeight: '30px', color: 'white', pl: 0.5 }}
                      >
                        {campaign !== null ? `| ${campaign?.name}` : ''}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Box>
                      {widgetNames[key] === 'Donations' ? (
                        <Typography
                          variant="h6"
                          sx={{ color: wsConnected ? 'green' : 'red', m: 1.3 }}
                        >
                          <strong>Live Feed</strong>
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Box>

                    <Box>
                      {}

                      <IconButton
                        sx={{ mt: 1 }}
                        aria-label="delete"
                        onClick={() => onRemoveItem(key)}
                      >
                        <CloseIcon fontSize="small" sx={{ color: 'white' }} />
                      </IconButton>
                    </Box>
                  </Stack>
                </div>
              </div>
              {widgetBody(widgetNames[key] as string)}
              <div className={classes.body} />
            </Card>
          </div>
        ))}
      </ResponsiveGridLayout>
      <Box>
        <WidgetsMenu
          items={items}
          onRemoveItem={onRemoveItem}
          onAddItem={onAddItem}
          originalItems={originalItems}
        />
      </Box>
    </div>
  )
}

function getFromLS(key: any) {
  let ls: any = {}
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('dashboard-layout')!) || {}
    } catch (e) {}
  }
  return ls[key]
}

function saveToLS(key: any, value: any) {
  if (global.localStorage) {
    global.localStorage.setItem(
      'dashboard-layout',
      JSON.stringify({
        [key]: value,
      }),
    )
  }
}
export default withSize()(Status)
