import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  TextField,
  Typography,
  Divider,
  Chip,
  DialogActions,
  Button,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import globalSettings from '../../../../../globalSettings'

interface CreateCommandDialogProps {
  open: boolean
  formValues: Command
  handleClose: () => void
  handleInputChange: (e: any) => void
  setFormValues: (value: React.SetStateAction<Command>) => void
  view: string
  handleChange: (event: React.MouseEvent<HTMLElement>, nextView: string) => void
  handleDeleteAlias: (alias: string) => void
  handleSubmit: any
  setCreateCommandAliases: any
  createCommandAliases: any
}

const MAX_ALIASES = 5

const CreateCommandDialog = ({
  open,
  formValues,
  handleClose,
  handleInputChange,
  setFormValues,
  handleChange,
  handleDeleteAlias,
  handleSubmit,
  setCreateCommandAliases,
  createCommandAliases,
}: CreateCommandDialogProps) => {
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center">
          <Grid>
            <DialogTitle>Create a command</DialogTitle>
          </Grid>
          <Grid sx={{ marginLeft: 'auto' }}>
            <Box>
              <FormControlLabel
                value="end"
                control={<Checkbox checked={formValues.enabled} />}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    enabled: !formValues.enabled,
                  })
                }}
                label="Enabled"
                labelPlacement="end"
              />
            </Box>
          </Grid>
        </Grid>
        <DialogContent>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: '100%', height: '100%' }}
          >
            <Box sx={{ width: '100%' }}>
              <Stack direction={'column'} spacing={2}>
                <TextField
                  error={
                    formValues.name.length >
                    globalSettings.forms.COMMAND_NAME_MAX
                      ? true
                      : false
                  }
                  helperText={
                    formValues.name.length >
                    globalSettings.forms.COMMAND_NAME_MAX
                      ? `The maximum amount of characters is ${globalSettings.forms.COMMAND_NAME_MAX} characters`
                      : ''
                  }
                  id="name"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required={true}
                  value={formValues.name}
                  onChange={handleInputChange}
                />
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  title="Aliases"
                  freeSolo
                  onChange={(e, value, situation, option) => {
                    // When removing an alias chip, it should update the form values
                    if (situation === 'removeOption') {
                      setCreateCommandAliases({
                        ...createCommandAliases!.filter((alias: any) => {
                          return alias !== option!.option
                        }),
                      })
                    }
                  }}
                  onKeyDown={(ev: any) => {
                    if (ev.key === 'Enter') {
                      console.log(ev.target.value)
                      ev.preventDefault()
                      // Initialize aliases if array is empty
                      if (createCommandAliases.length === 0) {
                        setCreateCommandAliases([ev.target.value.toLowerCase()])
                      } else {
                        // Append the new alias to the list
                        setCreateCommandAliases([
                          ...createCommandAliases,
                          ev.target.value.toLowerCase() as string,
                        ])
                      }
                    }
                  }}
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      return (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      )
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formValues.aliases !== null &&
                        formValues.aliases.length > MAX_ALIASES
                      }
                      variant="outlined"
                      label="Aliases"
                      placeholder=""
                      helperText={'Type in an alias and press enter to submit.'}
                    />
                  )}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Type of response
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formValues.response_type}
                    label="Type of response"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        response_type: e.target.value as
                          | 'say'
                          | 'mention'
                          | 'reply',
                      })
                    }}
                  >
                    <MenuItem value={'say'}>
                      <Stack>
                        <Typography><strong>Say</strong></Typography>
                        <Typography variant="caption">
                          Simply says the message in chat without replying or
                          mentioning anyone.
                        </Typography>
                      </Stack>
                    </MenuItem>
                    <MenuItem value={'mention'}>
                      <Stack>
                        <Typography><strong>Mention</strong></Typography>
                        <Typography variant="caption">
                          {'Will @ the user when ran.'}
                        </Typography>
                      </Stack>
                    </MenuItem>
                    <MenuItem value={'reply'}>
                      <Stack>
                        <Typography><strong>Reply</strong></Typography>
                        <Typography variant="caption">
                          Uses Twitch's reply feature.
                        </Typography>
                      </Stack>
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  error={
                    formValues.response.length >
                    globalSettings.forms.COMMMAND_RESPONSE_MAX
                      ? true
                      : false
                  }
                  helperText={
                    formValues.response.length >
                    globalSettings.forms.COMMMAND_RESPONSE_MAX
                      ? `The maximum amount of characters is ${globalSettings.forms.COMMMAND_RESPONSE_MAX}`
                      : ''
                  }
                  multiline
                  rows={6}
                  id="response"
                  name="response"
                  label={
                    'Response - ' +
                    formValues.response.length +
                    `/${globalSettings.forms.COMMMAND_RESPONSE_MAX} characters`
                  }
                  type="text"
                  fullWidth
                  variant="outlined"
                  required={true}
                  value={formValues.response}
                  onChange={handleInputChange}
                />
              </Stack>

              <Stack direction={'column'}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', marginTop: 2 }}
                  >
                    Conditions
                  </Typography>
                  <Typography variant="subtitle2">
                    Allow this command to be ran when your stream is:
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.enabled_online}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            setFormValues({
                              ...formValues,
                              enabled_online: checked,
                            })
                          }}
                        />
                      }
                      label="Online"
                      onChange={(event: any) => {
                        console.log(event)
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.enabled_offline}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            setFormValues({
                              ...formValues,
                              enabled_offline: checked,
                            })
                          }}
                        />
                      }
                      label="Offline"
                    />
                  </FormGroup>
                </Box>

                <Box>
                  <Stack spacing={1}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold', marginTop: 2 }}
                    >
                      Cooldowns
                    </Typography>
                    <Stack direction={'row'} spacing={1}>
                      <TextField
                        id="global_cooldown_field"
                        label="Global cooldown"
                        defaultValue={5}
                        error={
                          formValues.global_cooldown <
                          globalSettings.forms.COMMAND_GLOBAL_COOLDOWN_MIN
                        }
                        type="number"
                        fullWidth
                      />
                      <TextField
                        id="user_cooldown_field"
                        label="User cooldown"
                        defaultValue={15}
                        type="number"
                        fullWidth
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Divider />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            type="submit"
            disabled={
              formValues.name.length === 0 || formValues.response.length === 0
            }
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateCommandDialog
