import { Button, Container, Grid } from '@mui/material'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useAuthContext } from '../../context/context'
import { globalConfig } from '../../context/global'
import img1 from '../../images/home_images/1.jpg'
import img2 from '../../images/home_images/2.jpg'
import img3 from '../../images/home_images/3.jpg'
import '../../styles/index.css'

const images = [img1, img2, img3]

const Home = () => {
  const [cookies] = useCookies()
  const { token } = useAuthContext()

  useEffect(() => {
    document.title = 'USOBot | Home'
  })

  return (
    <main
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${
          images[Math.floor(Math.random() * images.length)]
        })`,
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 50vw rgba(0,33,71,0.6)',
      }}
    >
      <Container
        sx={{
          minWidth: '100%',
          height: '100vh',
        }}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <svg
            /* class="Logo-svg" */
            style={{ width: '15%', height: '15%', marginBottom: 20 }}
            role="img"
            aria-hidden="true"
            focusable="false"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 502.9 276.9"
          >
            <path fill="#bb133e" d="M0 0h486.8v276.9H0z"></path>
            <path fill="#fff" d="M15.3 17.5h456.2v241.7H15.3z"></path>
            <path
              fill="#002147"
              d="M30.8 30.2h425.3v216.3H30.8zm461.3 238.6a5.4 5.4 0 1 1 5.4 5.4 5.44 5.44 0 0 1-5.4-5.4m9.4 0a4.1 4.1 0 1 0-4.1 4.3 4.1 4.1 0 0 0 4.1-4.3m-6.1-3.1h2.3c1.5 0 2.3.5 2.3 1.8a1.52 1.52 0 0 1-1.6 1.6l1.6 2.7h-1.2l-1.6-2.6h-.7v2.6h-1.1v-6.1zm1.1 2.6h1c.7 0 1.3-.1 1.3-.9a1 1 0 0 0-1.2-.8h-1v1.7h-.1z"
            ></path>
            <path
              d="M181.7 53.5h-19.2v139.4c0 8.7-4.1 12.7-12.7 12.7h-4.6c-8.7 0-12.7-4.1-12.7-12.7V53.5h-19.2V192c0 20.9 10.3 31.3 31.3 31.3h6c20.9 0 31.3-10.3 31.3-31.3V53.5h-.2zm79 83.4l-23.4-16.8c-5.5-4.1-8.7-7.7-8.7-16.1V82.8c0-8.7 4.1-12.7 12.7-12.7h3.4c8.7 0 12.7 4.1 12.7 12.7v31h18.9V83.6c0-20.4-10.1-30.8-30.8-30.8h-4.8c-20.9 0-31.3 10.3-31.3 31.3v20.2c0 13.7 3.6 22.4 16.1 31.3l23.6 17.1c5.5 4.1 8.4 7.7 8.4 16.1V194c0 8.7-4.1 12.7-12.7 12.7H241c-8.7 0-12.7-4.1-12.7-12.7v-36.1h-18.8v35.3c0 20.4 10.1 30.8 30.8 30.8h5.3c20.9 0 31.3-10.3 31.3-31.3v-24.5c-.1-14.4-4-22.4-16.2-31.3m93.7-53.8v110.6c0 8.7-4.1 12.7-12.7 12.7h-5.8c-8.7 0-12.7-4.1-12.7-12.7V83.1c0-8.7 4.1-12.7 12.7-12.7h5.8c8.6 0 12.7 4 12.7 12.7m19.2 109.6V84.1c0-20.9-10.3-31.3-31.3-31.3h-7.2c-20.9 0-31.3 10.3-31.3 31.3v108.7c0 20.9 10.3 31.3 31.3 31.3h7.2c21-.1 31.3-10.5 31.3-31.4M70 60.7l5.6 17.2h18.2L79.1 88.6l5.6 17.4L70 95.3 55.2 106l5.7-17.4-14.7-10.7h18.2zm0 55.1l5.6 17.2h18.2l-14.7 10.6 5.6 17.4L70 150.3 55.2 161l5.7-17.4-14.7-10.7h18.2zm0 55.1l5.6 17.2h18.2l-14.7 10.7 5.6 17.4L70 205.5l-14.8 10.7 5.7-17.4-14.7-10.7h18.2zM416.7 60.7l5.6 17.2h18.2l-14.7 10.7 5.6 17.4-14.7-10.7-14.8 10.7 5.7-17.4-14.7-10.7h18.2zm0 55.1l5.6 17.2h18.2l-14.7 10.6 5.6 17.4-14.7-10.7-14.8 10.7 5.7-17.4-14.7-10.7h18.2zm0 55.1l5.6 17.2h18.2l-14.7 10.7 5.6 17.4-14.7-10.7-14.8 10.7 5.7-17.4-14.7-10.7h18.2z"
              fill="#fff"
            ></path>
          </svg>
          <Grid item xs={1}>
            {token === undefined || token === '' ? (
              <Button
                href={`${globalConfig.baseUrl}/login`}
                variant="contained"
              >
                Login with Twitch
              </Button>
            ) : (
              <Button
                href={
                  globalConfig.env === 'development'
                    ? 'http://localhost:3000/dashboard'
                    : 'https://usobot.org/dashboard'
                }
                variant="contained"
              >
                Continue to Dashboard
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </main>
  )
}

export default Home
