import {
  Typography,
  LinearProgress,
  Stack,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import { useAuthContext } from '../../../../../context/context'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
const normalise = (value: number, min: number, max: number) =>
  ((value - min) * 100) / (max - min)

const Tiltify = () => {
  const { campaign, makeRequest, token, setCampaign, user } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  })
  const [url, setUrl] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const handleInputChange = (e: any) => {
    const { value } = e.target
    setUrl(value)
    setError({
      error: false,
      message: '',
    })
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    makeRequest(
      '/tiltify/fundraiser',
      'POST',
      token,
      JSON.stringify({
        type: 'campaign',
        url: url,
      }),
    )
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)
        if (parsed.error) {
          // Alert user that there was an error
          if (parsed.error === 'invalid url') {
            setError({
              error: true,
              message: 'Invalid Tiltify URL.',
            })
            enqueueSnackbar('Invalid Tiltify URL.', {
              variant: 'error',
            })
          } else if (parsed.error === "couldn't find a campaign by that url") {
            setError({
              error: true,
              message: `Couldn't find a fundraiser by that URL.`,
            })
            enqueueSnackbar("Couldn't find a fundraiser by that URL.", {
              variant: 'error',
            })
          }
        } else {
          if (parsed.data) {
            setCampaign(parsed.data)

            makeRequest(
              '/accounts/@me',
              'PUT',
              token,
              JSON.stringify({
                channel_id: user.account.channel_id,
                fundraiser_url: url,
              }),
            )
              .then((data: any) => data)
              .then((json: any) => {
                const parsed: APIRequest = JSON.parse(json)
                if (parsed.error) {
                  // Alert user that there was an error
                  console.log('ERROR ', parsed.error)
                } else {
                  console.log(json)
                }
              })
          }
        }
      })
  }

  useEffect(() => {}, [campaign])

  return (
    <Box sx={{ width: '100%', height: '100vh', pr: 1, pl: 1, pt: 1, bt: 1 }}>
      <Stack direction="column" spacing={1.5}>
        <Grid container alignItems="center" sx={{ marginBottom: 1 }}>
          <Grid>
            <Stack direction={'column'}>
              <Typography variant="h6">Raised</Typography>
              <Typography variant="h5">
                <strong>${campaign?.amountRaised.toLocaleString()}</strong>
              </Typography>
            </Stack>
          </Grid>

          <Grid sx={{ marginLeft: 'auto' }}>
            <Stack direction={'column'}>
              <Typography variant="h6" sx={{ textAlign: 'right' }}>
                Goal
              </Typography>
              <Typography variant="h5">
                <strong>
                  ${campaign?.fundraiserGoalAmount.toLocaleString()}
                </strong>
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <LinearProgress
          sx={{ padding: 1 }}
          variant={campaign !== null ? 'determinate' : 'indeterminate'}
          color="primary"
          value={
            campaign !== null &&
            campaign.amountRaised >= campaign.fundraiserGoalAmount
              ? 100
              : normalise(
                  campaign !== null ? campaign.amountRaised : 0,
                  0,
                  campaign !== null ? campaign.fundraiserGoalAmount : 0,
                )
          }
        />

        <Box>
          <Button
            variant="contained"
            startIcon={<SettingsIcon />}
            onClick={handleClickOpen}
            disabled={user === null}
          >
            Settings
          </Button>
        </Box>
      </Stack>

      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Campaign Settings</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Fundraiser URL"
            type="email"
            fullWidth
            variant="standard"
            defaultValue={user.account.fundraiser_url}
            required
            helperText={error.error ? `Error: ${error.message}` : ''}
            onChange={handleInputChange}
            error={error.error ? true : false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={url.length === 0 ? true : false}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Tiltify
