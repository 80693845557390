import { Button, Grid, Typography } from '@mui/material'
import { useAuthContext } from '../../context/context'
import { globalConfig } from '../../context/global'

type GoHomeProps = {
  text: string
}

const GoHome = ({ text }: GoHomeProps) => {
  const { token } = useAuthContext()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3} sx={{ justifyContent: 'center', textAlign: 'center' }}>
        <Typography variant="h5" color={'black'}>
          {text}
        </Typography>
        {token === undefined ? (
          <Button href={`${globalConfig.baseUrl}/login`} variant="contained">
            Login with Twitch
          </Button>
        ) : (
          <Button
            href={
              globalConfig.env === 'development'
                ? 'http://localhost:3000/dashboard'
                : 'https://usobot.org/dashboard'
            }
            variant="contained"
          >
            Continue to Dashboard
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default GoHome
