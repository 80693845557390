import {
  Paper,
  Stack,
  Typography,
  Container,
  AppBar,
  Toolbar,
  Avatar,
  Grid,
  Button,
} from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../../images/logo'
import { GridColDef, DataGrid } from '@mui/x-data-grid'

// Fetches commands and data for a single channel
const ChannelCommands = () => {
  // let { channel } = useParams()
  const [
    userData
  ] = useState<ChannelCommandsResponseUserData | null>()
  /* const { token } = useAuthContext() */
  const [rows] = useState<ChannelCommandRow[] | null>(null)
  /* const [expanded, setExpanded] = useState<string | false>(false) */
  const [pageSize, setPageSize] = useState<number>(10)

 /*  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false)
  } */

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150, hide: true },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'response', headerName: 'Response', flex: 1 },
  ]

  /* useEffect(() => {
    makeRequest('/modules/commands', 'GET', token)
      .then((data: any) => data)
      .then((json: any) => {
        const parsed: APIRequest = JSON.parse(json)

        if (parsed.data) {
          const json = JSON.parse(data.data)
          console.log(json.data)
          setUserData({
            display_name: json.data.display_name,
            login: json.data.login,
            pfp: json.data.pfp,
          })

          const newRows: ChannelCommandRow[] = []
          json.data.commands.forEach((cmd: Command) => {
            newRows.push({
              id: cmd.id,
              name: cmd.name,
              response: cmd.response,
            })
          })

          setRows(newRows)
        } else {
          console.log('Error: ', data.data)
        }
      })

    const req = makeRequest(`/commands/${channel}`, 'GET', '', null)
    validateRequest('', req).then((data) => {
      if (!data.error) {
        const json = JSON.parse(data.data)
        console.log(json.data)
        setUserData({
          display_name: json.data.display_name,
          login: json.data.login,
          pfp: json.data.pfp,
        })

        const newRows: ChannelCommandRow[] = []
        json.data.commands.forEach((cmd: Command) => {
          newRows.push({
            id: cmd.id,
            name: cmd.name,
            response: cmd.response,
          })
        })

        setRows(newRows)
      } else {
        console.log('Error: ', data.data)
      }
    })
  }, [channel]) */

  return (
    <Container>
      <AppBar
        color="primary"
        position="fixed"
        sx={{ width: `100%` }}
        enableColorOnDark
      >
        <Toolbar color="primary">
          <Link to="/">
            <Logo />
          </Link>
        </Toolbar>
        <Paper
          elevation={1}
          sx={{
            padding: 4,
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Stack direction={'column'} spacing={2} sx={{ width: '70%' }}>
            <Box>
              <Grid container alignItems="center">
                <Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <Avatar
                        alt=""
                        src={userData?.pfp}
                        sx={{ width: 64, height: 64 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">
                        {userData?.display_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ marginLeft: 'auto' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    href={`http://twitch.tv/${userData?.login}`}
                  >
                    Twitch
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box>
              {rows !== null ? (
                <DataGrid
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  pagination
                  autoHeight
                  rows={rows}
                  columns={columns}
                />
              ) : (
                <></>
              )}
            </Box>
          </Stack>
        </Paper>
      </AppBar>
    </Container>
  )
}

export default ChannelCommands
/* function makeRequest(arg0: string, arg1: string, token: any) {
  throw new Error('Function not implemented.')
} */
