const globalSettings = {
  forms: {
    COMMAND_NAME_MIN: 1,
    COMMAND_NAME_MAX: 255,
    COMMAND_ALIAS_MAX: 10,
    COMMAND_ALIAS_MIN_LENGTH: 1,
    COMMAND_ALIAS_MAX_LENGTH: 255,
    COMMAND_RESPONSE_MIN: 1,
    COMMMAND_RESPONSE_MAX: 500,
    COMMAND_GLOBAL_COOLDOWN_MIN: 1,
    COMMAND_GLOBAL_COOLDOWN_MAX: 10000,
    COMMAND_USER_COOLDOWN_MIN: 1,
    COMMAND_USER_COOLDOWN_MAX: 10000,
  },
}

export default globalSettings
